import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import paths from "../../paths";
import { useEffect, useState } from "react";
import { auth, authSubject } from "../../store/auth.store";
import { copyMemberNumber, useForceUpdate } from "../../services/misc.functions";
import iconMenu from "../../assets/feather/menu.svg";
import { userLogout } from "../../services/auth.service";
import iconMember from "../../assets/feather/user.svg";
import iconPhone from "../../assets/feather/phone.svg";
import iconTickets from "../../assets/feather/clipboard.svg";
import iconDeposit from "../../assets/feather/download.svg";
import iconWithdraw from "../../assets/feather/upload.svg";
import iconPassword from "../../assets/feather/lock.svg";
import iconPocket from "../../assets/feather/server.svg";
import iconTransactions from "../../assets/feather/list.svg";
import iconLogout from "../../assets/feather/log-out.svg";
import iconHeadphones from "../../assets/feather/headphones.svg";
import iconCampaign from "../../assets/icons/campaign.svg";
// import esansIcon from "../../assets/icons/esans-icon.png";

import iconNotification from "../../assets/feather/bell.svg";

import { isMobile } from "../../App";
import { path } from "../../store/misc.store";

function UserMenu() {
  return (
    <ul id="member-dropdown-menu" className="member-dropdown-menu">
      <li>
        <Link to={paths.memberInfo.url}>
          <img className="svgcolor" src={iconMember} alt="-" /> HESABIM
        </Link>
      </li>
      <li>
        <Link to={paths.notificationInfo.url}>
          <img className="svgcolor" src={iconPhone} alt="-" /> BİLDİRİM TERCİHLERİ
        </Link>
      </li>
      <li>
        <Link to={paths.memberTickets.url}>
          <img className="svgcolor" src={iconTickets} alt="-" />
          BİLETLERİM
        </Link>
      </li>

      <li>
        <Link to={paths.deposit.url}>
          <img className="svgcolor" src={iconDeposit} alt="" />
          PARA YATIR
        </Link>
      </li>
      <li>
        <Link to={paths.withdraw.url}>
          <img className="svgcolor" src={iconWithdraw} alt="-" />
          PARA ÇEK
        </Link>
      </li>

      <li>
        <Link to={paths.changePassword.url}>
          <img className="svgcolor" src={iconPassword} alt="-" />
          ŞİFRE DEĞİŞTİR
        </Link>
      </li>

      <li>
        <Link to={paths.memberBankAccounts.url}>
          <img className="svgcolor" src={iconPocket} alt="-" />
          BANKA HESAPLARIM
        </Link>
      </li>
      <li>
        <Link to={paths.memberTransactions.url}>
          <img className="svgcolor" src={iconTransactions} alt="-" />
          HESAP HAREKETLERİ
        </Link>
      </li>
      <li>
        <Link to={paths.support.url + "/cozum-merkezi"}>
          <img className="svgcolor" src={iconHeadphones} alt="-" />
          YARDIM
        </Link>
      </li>
      <li>
        <Link to={paths.announcement.url}>
          <img className="svgcolor" src={iconCampaign} alt="-" />
          DUYURULAR
        </Link>
      </li>
      <li>
        <a
          className="cursor-pointer"
          onClick={() => {
            userLogout();
          }}
        >
          <img className="svgcolor" src={iconLogout} alt="-" />
          ÇIKIŞ YAP
        </a>
      </li>
    </ul>
  );
}

export function Header() {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  // fixme ana sayfa hariç görünmemeli
  useEffect(() => {
    forceUpdate();
    setShowMenu(false);
  }, [pathname]);

  const handleClickOutside = (event: any) => {
    let ref: any = document.getElementById("member-dropdown-menu");
    if (ref?.contains(event.target) === true) {
    } else {
      if (event.target.id !== "menu") {
        setShowMenu(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (isMobile && path.root === paths.register.url.replace("/", "")) {
    return <></>;
  }

  return (
    <header>
      <h5 className="my-0  font-weight-normal ">
        <Link to={paths.home.url}>
          <img className="nav-logo" alt="4NALA" src="/assets/logo_tjk_at_yarisi_hipodrom_4nala_atyarisi.svg" />
        </Link>
      </h5>

      {!isMobile && (
        <nav className="ms-auto">
          {/*<Link to={paths.home.url} className={"navItem"}>ANASAYFA</Link>*/}
          <Link to={paths.betting.url} className={"navItem " + paths.betting.url.replace("/", "")}>
            BAHİS YAP
          </Link>
          <Link to={paths.program.url} className={"navItem " + paths.program.url.replace("/", "")}>
            PROGRAM
          </Link>
          <Link to={paths.results.url} className={"navItem " + paths.results.url.replace("/", "")}>
            SONUÇLAR
          </Link>
          <Link to={paths.stream.url} className={"navItem " + paths.stream.url.replace("/", "")}>
            TJK TV
          </Link>
          <Link to={paths.tipsterCoupons.url} className={"navItem " + paths.tipsterCoupons.url.replace("/", "")}>
            HAZIR KUPONLAR
          </Link>
          <Link to={paths.tipsterComments.url} className={"navItem " + paths.tipsterComments.url.replace("/", "")}>
            YORUMLAR
          </Link>
          <Link to={paths.campaigns.url} className={"navItem " + paths.campaigns.url.replace("/", "")}>
            KAMPANYALAR
          </Link>
          <Link to={paths.raffles.url} className={"navItem" + paths.raffles.url.replace("/", "")}>
            <img src={"../../assets/icons/esans-icon.png"} width={18} height={18} style={{ marginRight: "3px" }} />
            ŞANS OYUNLARI
          </Link>
          {auth.authentication && auth.member && auth.member.id ? (
            <>
              <div
                className="user-balance"
                onClick={() => {
                  setShowMenu((prevShowMenu) => !prevShowMenu);
                }}
              >
                <label>Bakiye:</label>
                <div className="lines">
                  <span>{auth.member.balance_debit}₺</span>
                  <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
                </div>
              </div>
              <div
                className="user-notification"
                onClick={() => {
                  navigate(paths.notifications.url);
                }}
              >
                <img src={iconNotification} alt="N" />
                {auth?.member?.unread_notification_count && auth.member.unread_notification_count > 0 ? (
                  <span className="position-absolute p-2 translate-middle text-white badge rounded-pill bg-danger">
                    {auth.member.unread_notification_count}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="user-tickets"
                onClick={() => {
                  navigate(paths.memberTickets.url);
                }}
              >
                BİLETLERİM
              </div>
              <div className="user-profile">
                <a
                  className="float-end mt-2 cursor-pointer"
                  onClick={() => {
                    setShowMenu((prevShowMenu) => !prevShowMenu);
                  }}
                >
                  <img src={iconMenu} alt="N" id={"menu"} />
                </a>

                <span className="nowrap-ellipsis pe-2">
                  {" "}
                  <b>{auth.member.first_name}</b>
                </span>
                <span
                  onClick={() => {
                    copyMemberNumber(auth.member.id, "Üye numaranız kopyalandı!");
                  }}
                >
                  {" "}
                  <b>{auth.member.id}</b>
                </span>

                {showMenu && <UserMenu />}
              </div>
            </>
          ) : (
            <>
              <Link className="login px-3" to={paths.login.url}>
                GİRİŞ YAP
              </Link>
              <Link className="register px-3" to={paths.register.url}>
                ÜYE OL
              </Link>
            </>
          )}
        </nav>
      )}

      {isMobile && (
        <nav className="float-end">
          {auth.authentication && auth.member && auth.member.id ? (
            <>
              <div className={"menu-button"}>
                <a
                  className="float-end mt-2 cursor-pointer"
                  onClick={() => {
                    setShowMenu((prevShowMenu) => !prevShowMenu);
                  }}
                >
                  <img src={iconMenu} alt="N" id={"menu"} />
                </a>
              </div>
              <div
                className="user-balance d-flex align-items-center"
                onClick={() => {
                  setShowMenu((prevShowMenu) => !prevShowMenu);
                }}
              >
                {/*<label>Bakiye:</label>*/}
                <div className="lines">
                  <span>{auth.member.balance_debit}₺</span>
                  <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
                </div>
              </div>
              <div
                className="user-notification"
                onClick={() => {
                  navigate(paths.notifications.url);
                }}
              >
                <img src={iconNotification} alt="N" />
                {auth?.member?.unread_notification_count && auth.member.unread_notification_count > 0 ? (
                  <span className="position-absolute px-2 translate-middle text-white badge rounded-pill bg-danger">
                    {auth.member.unread_notification_count}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="user-profile"
                onClick={() => {
                  setShowMenu((prevShowMenu) => !prevShowMenu);
                }}
              >
                <span className={"user-name"}>
                  <b>{auth.member.first_name}</b>
                </span>
                <span
                  onClick={() => {
                    copyMemberNumber(auth.member.id, "Üye numaranız kopyalandı!");
                  }}
                >
                  <b>{auth.member.id}</b>
                </span>
                {showMenu && <UserMenu />}
              </div>
            </>
          ) : (
            <>
              <Link className="btn btn-sm login" to={paths.login.url}>
                GİRİŞ YAP
              </Link>
              <Link className="btn btn-sm register" to={paths.register.url}>
                ÜYE OL
              </Link>
            </>
          )}
          {/*<button className=""><img src={iconMenu} /></button>*/}
        </nav>
      )}
    </header>
  );
}
