// Genel kullanım için description ve keywords değerlerini değiştirebilirsiniz.

import { HorseStats } from "./pages/jockeys/horseStats";

const description =
  "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.";
const keywords =
  "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala";

const paths: any = {
  home: {
    url: "/",
    title: "4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  login: {
    url: "/giris-yap",
    title: "GİRİŞ YAP - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  logout: {
    url: "/cikis-yap",
    title: "ÇIKIŞ YAP - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberInfo: {
    url: "/uyelik-bilgilerim",
    title: "UYELİK BİLGİLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  notificationInfo: {
    url: "/bildirim-tercihlerim",
    title: "UYELİK BİLGİLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberTickets: {
    url: "/biletlerim",
    title: "BİLETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberSavedTickets: {
    url: "/biletlerim/kayitli",
    title: "BİLETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberBankAccounts: {
    url: "/banka-hesaplarim",
    title: "BANKA HESAPLARIM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberTransactions: {
    url: "/hesap-hareketlerim",
    title: "HESAP HAREKETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  cookieSettings: {
    url: "/cerez-ayarlari",
    title: "HESAP HAREKETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  withdraw: {
    url: "/para-cek",
    title: "PARA ÇEK - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  deposit: {
    url: "/para-yatir",
    title: "PARA YATIR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  support: {
    url: "/yardim-at-yarisi-bahis-tjk",
    title:
      "YARDIM, OYUN KURALLARI, NASIL TJK KUPON YAPARIM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  announcement: {
    url: "/tjk-duyurulari-haberler",
    title:
      "DUYURULAR, HABERLER,  KOŞMAZ AT, YASAKLI JOKEYLER - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  agreement: {
    url: "/sozlesmeler",
    title: "SÖZLEŞMELER - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  notifications: {
    url: "/bildirimlerim",
    title: "BİLDİRİMLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  flatpage: {
    url: "/fp",
    title: " 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  stream: {
    url: "/tjk-tv-atyarisi-izle-canli-yayin",
    title:
      "TJK TV, AT YARIŞI İZLE CANLI YAYIN, - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  tipsterCoupons: {
    url: "/hazir-kuponlar-yorumcular-at-yarisi-tahminleri",
    title: "HAZIR KUPONLAR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "At yarışı tahminci ve yorumcularının Hazır kuponları. Hazır 6lı kuponlar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "6lı, altılı kuponlar, hazır kuponlar, at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  tipsterComments: {
    url: "/yorumlar-yorumcular-at-yarisi-tahminleri",
    title: "HAZIR KUPONLAR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "At yarışı tahminci ve yorumcularının Hazır kuponları. Hazır 6lı kuponlar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "6lı, altılı kuponlar, hazır kuponlar, at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  register: {
    url: "/uye-ol",
    title: "HEMEN ÜYE OL - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  resetPassword: {
    url: "/sifre-sifirla",
    title: "ŞİFREMİ UNUTTUM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  changePassword: {
    url: "/sifre-degistir",
    title: "ŞİFRE DEĞİŞTİR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  verifyEmail: {
    url: "/eposta-dogrula",
    title: "EPOSTA DOĞRULA - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  betting: {
    url: "/tjk-at-yarisi-bulteni-bahis-yap",
    title: "BAHİS YAP - AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  program: {
    url: "/tjk-at-yarisi-programi",
    title: "4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  results: {
    url: "/tjk-at-yarisi-sonuclari",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  campaigns: {
    url: "/kampanyalar",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  reportedJokeys: {
    url: "/tjk-raporlu-cezalı-jokeyler",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  horseStats: {
    url: "/tjk-kosmayan-atlar-jokey-degisikligi",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  raffles: {
    url: "/esya-piyangosu",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  games: {
    url: "/dijital-oyunlar",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  superWheel: {
    url: "/super-carkifelek",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  rockPaperScissors: {
    url: "/tas-kagit-makas",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  zeplin: {
    url: "/zeplin",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  scratch: {
    url: "/kazi-kazan",
    title: "Online Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
    description:
      "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
    keywords:
      "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
  },
  scratchMyCards: {
    url: "/kazi-kazan/kartlarim",
    title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
    description:
      "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
    keywords:
      "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
  },
  scartchRules: {
    url: "/kazi-kazan/kurallar",
    title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
    description:
      "Türkiye'nin yeni yasal şans oyunları platformu E-Şans'ta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
    keywords:
      "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
  },
};

export default paths;
