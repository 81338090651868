import React, { useEffect, useState } from "react";
import "./raffle.css";
import { useForceUpdate } from "../../services/misc.functions";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import paths from "../../paths";

import Draw from "./draw";
import { RaffleResults } from "./results";
import { MyRaffleOrders } from "./my.raffle.orders";
import { DrawDetail } from "./widgets/draw.detail";
import { cartStore, cartToggleSubject, raffleDraws, raffleSubject } from "../../store/raffle.store";
import { RaffleSlider } from "./widgets/raffle.slider";
import { isMobile } from "../../App";
import { MobileRaffleCart, RaffleCart } from "./raffle.cart";
import { openRaffleDetail } from "../../services/raffle.service";
import { RaffleModel } from "../../models/raffle.model";
import oyunlar from "../../assets/icons/oyunlar.svg";
import eSansWhite from "../../assets/icons/e-sans-white.svg";
import cekilis from "../../assets/icons/cekilis.svg";
import { Cart } from "../../models/cart.model";

let cartItemCount = 0;

const tabs = [
  { value: "cekilisler", label: "ÇEKİLİŞLER" },
  { value: "biletlerim", label: "BİLETLERİM" },
  { value: "cekilis-sonuclari", label: "ÇEKİLİŞ SONUÇLARI" },
];

export default function Raffle() {
  const param = useParams();
  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const forceUpdate = useForceUpdate();
  const [showCart, setShowCart] = useState(false);

  const reCalc = () => {
    cartItemCount =
      cartStore.items.length > 0
        ? cartStore.items
            .map((el: Cart) => el.quantity)
            .reduce(function (a: any, b: any) {
              return Number(a) + Number(b);
            })
        : 0;
  };

  useEffect(() => {
    if (raffleDraws.length > 0 && param.draw_id) {
      let d = raffleDraws.find((el: RaffleModel) => el.id === Number(param.draw_id));

      if (d) {
        const queryParams = new URLSearchParams(window.location.search);
        const next = queryParams.get("n");
        if (next) {
          navigate(next);
        } else {
          // document.title = env.pageTitle;
          window.history.pushState(null, "", paths.raffleTickets.url);
        }

        openRaffleDetail(d);
      }
    }
  }, [raffleDraws.length]);

  useEffect(() => {
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe((res: any) => {
      if (isMobile) {
        forceUpdate();
        reCalc();
      }
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let raffleSubject_subscriber = raffleSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      raffleSubject_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (param.slug) {
      var _tab = tabs.find((el: any) => el.value === param.slug);
      // console.log(param.slug);
      if (_tab) {
        setSelectedTab(_tab);
      }
    }

    return () => {};
  }, [pathname]);

  const handleSelect = (e: any) => {
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
    }
  };

  useEffect(() => {
    // console.log("Subscribing to cartToggleSubject");
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe({
      next: (value: any) => {
        setShowCart(value.show);
      },
    });

    return () => {
      console.log("Unsubscribing from cartToggleSubject");
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  return (
    <div className="row gap-3">
      {!isMobile && <RaffleSlider />}

      {isMobile && param.slug !== "sepet" && (
        <div className={"d-flex justify-content-center align-items-center mt-2"}>
          <img src={eSansWhite} height={25} style={{ marginRight: "0.5rem" }} />
          <h2 className={"text-white mb-0"}>Şans Oyunları</h2>
        </div>
      )}
      {!isMobile && (
        <div className="row gap-4 flex-nowrap p-0 m-0">
          <div className={`page-widget p-0`}>
            <svg style={{ display: "none" }}>
              <defs>
                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
              </defs>
            </svg>
            <div className={"tab-scroll"}>
              {/* Tab Header */}
              <ul className="nav nav-tabs">
                {tabs.map(({ value, label }, index) => {
                  return (
                    <li
                      key={`raffle-tabs-${index}`}
                      className={`nav-item raffle-item ${selectedTab.value === value ? "active" : ""}`}
                      onClick={handleSelect}
                    >
                      <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                      <a className="nav-link" id={`${index}`}>
                        {value === "biletlerim" ? (
                          <img
                            src={"../../assets/icons/esans-icon.png"}
                            width={18}
                            height={18}
                            style={{ marginRight: "5px" }}
                          />
                        ) : (
                          <></>
                        )}
                        {label}
                      </a>
                      <svg className="right" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* Tab Content */}
            <div className="p-2">
              <div className="row p-0">
                <div className={`${selectedTab.value === "cekilisler" || showCart ? "col-8" : "col-12"}`}>
                  {selectedTab.value === "cekilisler" && <Draw />}
                  {selectedTab.value === "biletlerim" && <MyRaffleOrders />}
                  {selectedTab.value === "cekilis-sonuclari" && <RaffleResults />}
                </div>
                <div className="col-4">
                  {(selectedTab.value === "cekilisler" && (
                    <RaffleCart navToTickets={() => setSelectedTab(tabs[1])} />
                  )) ||
                    (showCart && <RaffleCart navToTickets={() => setSelectedTab(tabs[1])} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && param.slug !== "sepet" && (
        <div className={"col-12"}>
          <div className={"mobile-tab-widget"}>
            <div className={"row"}>
              <div className={"col-6"}>
                <div className="raffle-tab-item selected">
                  <img alt={"program"} src={cekilis} />
                  {/*<span className={"raffle-tab-item-badge"}>Yeni</span>*/}
                  <div>
                    <h2>
                      EŞYA
                      <br />
                      PİYANGOSU
                    </h2>
                  </div>
                </div>
              </div>
              <div className={"col-6"}>
                <div
                  className="raffle-tab-item border"
                  onClick={() => {
                    navigate(paths.games.url);
                  }}
                >
                  <img alt={"program"} src={oyunlar} />
                  {/*<span className={"raffle-tab-item-badge"}>Yakında</span>*/}
                  <div>
                    <h2>
                      DİJİTAL
                      <br />
                      OYUNLAR
                    </h2>
                  </div>
                </div>
              </div>
              {/* <div className={"col-3"}>
                                <div className="raffle-tab-item">
                                    <img alt={"program"} src={programIcon}/>
                                    <span className={"raffle-tab-item-badge"}>Yakında</span>
                                    <div>
                                        <h2>SAYISAL OYUNLAR</h2>
                                    </div>
                                </div>
                            </div> */}
              {/* <div className={"col-3"}>
                                <div className="raffle-tab-item">
                                    <img alt={"program"} src={programIcon}/>
                                    <span className={"raffle-tab-item-badge"}>Yakında</span>
                                    <div>
                                        <h2>SAYISAL OYUNLAR</h2>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className={"border-bottom-dotted"}></div>
            <div className={"row"}>
              <div className={"col-4"}>
                <div
                  onClick={() => {
                    setSelectedTab(tabs[0]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "cekilisler" ? "selected" : ""}`}
                >
                  ÇEKİLİŞLER
                </div>
              </div>
              <div className={"col-4"}>
                <div
                  onClick={() => {
                    setSelectedTab(tabs[1]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "biletlerim" ? "selected" : ""}`}
                >
                  BİLETLERİM
                </div>
              </div>
              <div className={"col-4"}>
                <div
                  onClick={() => {
                    setSelectedTab(tabs[2]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "cekilis-sonuclari" ? "selected" : ""}`}
                >
                  SONUÇLAR
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Mobile Tab Content */}
      {isMobile && param.slug !== "sepet" && (
        <>
          <div className={"col-12"}>
            {selectedTab.value === "cekilisler" && <Draw />}
            {selectedTab.value === "cekilis-sonuclari" && <RaffleResults />}
            {selectedTab.value === "biletlerim" && <MyRaffleOrders />}
          </div>
        </>
      )}
      {isMobile && param.slug !== "sepet" && selectedTab.value === "cekilisler" && (
        <div className={"col-12"}>
          <div className="mobile-raffle-cart sticky">
            <div className="row">
              <div className="col-12">
                <h2>BİLETİM</h2>
              </div>
            </div>
            <div className={"mobile-raffle-cart-total"}>
              <div className="row">
                <div className={"col-3 text-end"}>
                  BİLET ADEDİ:
                  <br /> <b>{cartItemCount}</b>
                </div>
                <div className={"col-4 text-end"}>
                  TOPLAM TUTAR:
                  <br />{" "}
                  <b>
                    {cartStore.items
                      ?.reduce((accumulator: any, item: any) => {
                        const price = Number(item.item.ticket_price);
                        const quantity = Number(item.quantity) || 1;
                        return accumulator + price * quantity;
                      }, 0)
                      .toFixed(2)}
                    ₺
                  </b>
                </div>
                <div className={"col-5 text-center"}>
                  <button
                    className="btn btn-primary ms-3 fw-bold"
                    onClick={() => {
                      navigate(`${paths.raffles.url}/sepet`);
                    }}
                  >
                    SEPETE GİT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {param.slug === "sepet" && <MobileRaffleCart navToTickets={() => setSelectedTab(1)} />}

      <DrawDetail></DrawDetail>
    </div>
  );
}
