import "./raffle.slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import { useForceUpdate } from "../../../services/misc.functions";
import { isMobile } from "react-device-detect";
import cekilis from "../../../assets/icons/cekilis.svg";
import oyunlar from "../../../assets/icons/oyunlar.svg";
import paths from "../../../paths";

export function RaffleSlider() {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // fixme ana sayfa hariç görünmemeli
  useEffect(() => {
    forceUpdate();
  }, [pathname]);

  return (
    <div className="container p-0">
      <div className="games-widgets">
        <Swiper
          spaceBetween={isMobile ? 5 : 10}
          modules={[Navigation, Pagination]}
          navigation={
            isMobile
              ? false
              : {
                  prevEl: ".prev",
                  nextEl: ".next",
                }
          }
          pagination={isMobile ? { clickable: true } : false}
          onSlideChange={(swiper: any) => {
            // if (isMobile) {
            //   changeSelectedHippodrome(availableHippodromes[swiper.activeIndex]);
            // }
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            960: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // when window width is >= 640px
            1280: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide key={`games-widget-slider`}>
            <div
              className={`item cursor-pointer ${pathname === paths.raffles.url ? "active" : ""}`}
              onClick={() => {
                navigate(paths.raffles.url);
              }}
            >
              <div className={`${isMobile ? "w-50" : "w-100"} d-flex justify-content-center gap-2 align-items-center`}>
                <img alt={"program"} src={cekilis} width={22} />
                <span className={"nowrap-ellipsis"}>EŞYA PİYANGOSU</span>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide key={`games-widget-slider-2`}>
            <div
              className={`item cursor-pointer ${pathname === paths.games.url ? "active" : ""}`}
              onClick={() => {
                navigate(paths.games.url);
              }}
            >
              <div className={`${isMobile ? "w-50" : "w-100"} d-flex justify-content-center gap-2 align-items-center`}>
                <img alt={"program"} src={oyunlar} width={22} />
                <span className={"nowrap-ellipsis me-1"}>DİJİTAL OYUNLAR</span>

                {/* <span className="badge bg-danger">Yakında</span> */}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* 
        <div className="swiper-button-prev prev"></div>
        <div className="swiper-button-next next"></div> */}
      </div>
    </div>
  );
}
