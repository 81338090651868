import iconClock from "../../assets/feather/clock.svg";
import React, {useEffect, useState} from "react";
import {availableHippodromes, racesActionSubject} from "../../store/bulletin.store";
import {bulletinProvider} from "../../services/bulletin.service";
import {useForceUpdate} from "../../services/misc.functions";
import {Storage} from "../../services/localstorege.service";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {MobileTicket, Ticket} from "./components/ticket";
import {isMobile} from "../../App";
import {useParams} from "react-router-dom";
import {tipstersData} from "../../store/misc.store";

export function Coupons(props:any){
  const params = useParams();

  const forceUpdate = useForceUpdate();
  const [hippodrome, setHippodrome] = useState<any>(null);
  const [coupons, setCoupons] = useState<any>(null)
  const [tipsters, setTipsters] = useState<any>(null)
  const [selectedTipster, setSelectedTipster] = useState<any>(null)




  useEffect(() => {
    let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
      if (res && res.action === 'update') {
        forceUpdate();
      }
    })
    return () => {
      racesActionSubject_subscription.unsubscribe();
    }
  });

  bulletinProvider.getAvailableHippodromes();

  if (!hippodrome && availableHippodromes.length > 0) {
    if (params.tab != null) {
      let selectedHippodrome = availableHippodromes.filter((el: any) => {
        return el.keyword.toLowerCase()+el._id === params.tab?.toLowerCase();
      });
      if (selectedHippodrome.length > 0) {
        setHippodrome(selectedHippodrome[0])
      } else {
        setHippodrome(availableHippodromes[0]);
      }
    } else if(props.hippodrome){
      setHippodrome(props.hippodrome)
    } else {
      setHippodrome(availableHippodromes[0]);
    }
  }


  useEffect(() => {
    if(hippodrome) {
      Storage.del(`tipsterCoupons-${hippodrome?.card_id}`);
      let cachedData = Storage.get(`tipsterCoupons-${hippodrome?.card_id}`);
      if (!cachedData) {
        setCoupons(false);
        let api = new ApiService()
        api.start('post', env.tipsters_api + '/games/api/tjk/tipster-coupons', {card_id: hippodrome.card_id}, false)
          .then((res: any) => {
            Storage.set(`tipsterCoupons-${hippodrome.card_id}`, res.data, 1 * 60 * 1000)
            if (res.data.error != null){
              setCoupons([]);
            }else{
              setCoupons(res.data)
              let tipstersUniqeList: any = []
              res.data.map((el: any) => {
                if (tipstersUniqeList.indexOf(el.member_id.toString()) === -1) {
                  tipstersUniqeList.push(el.member_id.toString());
                }
              })
              setTipsters(tipstersUniqeList)
            }


          })
      } else {
        setCoupons(cachedData);
        let tipstersUniqeList: any = []
        cachedData.map((el: any) => {
          if (tipstersUniqeList.indexOf(el.member_id.toString()) === -1) {
            tipstersUniqeList.push(el.member_id.toString());
          }
        })
        setTipsters(tipstersUniqeList)
      }
    }
  }, [hippodrome]);

  if(!hippodrome){
    return <></>
  }

  return (<>
    {!props.hippodrome && <div className="bettingtype-options p-3 mt-4">
      <div className="row">

        <div className={`col-12 col-lg-4`}>
          <label>HİPODROM</label>
          {hippodrome ? <select value={hippodrome.keyword} onChange={(e: any) => {
            setHippodrome(availableHippodromes.find((data: any) => data.keyword === e.target.value));
            setSelectedTipster(null);
          }}>
            {availableHippodromes.map((data: any, key: any) => {
              return (<option value={data.keyword} key={`available-hippodrome-${key}`}>{data.location}</option>)
            })}
          </select> : null}

        </div>
        <div className={`col-12 col-lg-4 mt-2 mt-lg-0`}>
          <label>Uzmanlar</label>
          {tipsters ? <select value={selectedTipster ?? 'all'} onChange={(e: any) => {
            if (e.target.value === "all") {
              setSelectedTipster(null);
            } else {
              setSelectedTipster(e.target.value);
            }
          }}>
            <option value={"all"}>Hepsi</option>
            {tipsters.map((data: any, key: any) => {
              return (<option value={data} key={`available-tipsters-${key}`}>

                {tipstersData[data].nick_name ?? `${tipstersData[data].first_name} ${tipstersData[data].last_name}`}
              </option>)
            })}
          </select> : null}

        </div>

      </div>
    </div>}
    <div className="row">
      <div className="col">
        <div className="page-widget">

          <div className="p-3">

            {coupons == null && <>
              <div className="row">
                {Array.from(Array(12).keys()).map((ticket, index) => {
                  return (
                      <div className="col-12 col-lg-3" key={`home-tickets-${index}`}>
                        <div className="ticket-widget skeleton">
                          <div className="header">
                          </div>
                          <div className="body">
                          </div>
                          <div className="footer">
                          </div>
                        </div>
                      </div>
                  );
                })}
              </div>
            </>}


            {coupons && coupons.length === 0 && <p className="p-4 text-center">
              <img alt={"iconClock"} src={iconClock} width={64}/> <br/><br/>
              Seçtiğiniz hipdromdaki koşular için henüz hazır kupon paylaşılmamış, <br/> Uzman tahmincilerimiz sizler
              için en kısa zamanda tahminlerini paylaşacaktır.</p>}


            <div className="row">
              {coupons && coupons.length > 0 && coupons.map((ticket: any, index: number) => {
                if (selectedTipster){
                  if (ticket.member_id.toString() === selectedTipster.toString()){
                    return (
                        <div className="col-12 col-lg-3 mb-4" key={`home-tickets-${index}`}>
                          {isMobile ?  <MobileTicket ticket={ticket} hippodrome={hippodrome}/> : <Ticket ticket={ticket} hippodrome={hippodrome}/>}
                        </div>
                    );
                  }
                }else{
                  return (
                      <div className="col-12 col-lg-3 mb-4" key={`home-tickets-${index}`}>
                        {isMobile ?  <MobileTicket ticket={ticket} hippodrome={hippodrome}/> : <Ticket ticket={ticket} hippodrome={hippodrome}/>}
                      </div>
                  );
                }

              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
