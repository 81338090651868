import React, {useEffect, useState} from "react";
import {ApiService} from "../../../services/http.service";
import {agfUrlPrefix} from "../../../constants/global.vars";
import {Storage} from "../../../services/localstorege.service";
import iconFather from "../../../assets/feather/father-at-yarisi.svg";
import iconClock from "../../../assets/feather/clock.svg";
import {isMobile} from "../../../App";

let agfInterval: any = null;

export function AGF(prop: any) {
    const [agfData, setAgfData] = useState<any>(Storage.get("agfData"))
    const [hippodrome, setHippodrome] = useState<any>(prop.hippodrome);
    const [shownWager, setShownWager] = useState<any>(0);
    const [shownMobileRun, setShownMobileRun] = useState<any>(0);

    const getAgfData = () => {

        if (window.location.pathname.includes('agf')){
            let api = new ApiService()
            api.start("get", agfUrlPrefix, null, false).then((res: any) => {
                if (res.status) {
                    setAgfData(res.data);
                    console.log(agfInterval);
                    if (agfInterval === null) {
                        agfInterval = setInterval(getAgfData, 1 * 1000);
                    }
                }
            })
        }else{
            clearInterval(agfInterval);
        }

    }

    useEffect(() => {
        if (!agfData) {
            getAgfData();
        }
    }, []);

    let eqStyles: any = {
        "1": {transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)'},
        "2": {
            transform: 'scaleX(-1)',
            marginLeft: 5,
            filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)'
        },
        "3": {transform: 'scaleX(-1)', marginLeft: 5},
    }

    const getMinimalName = (name: string) => {
        if (name.split(' ').length > 1) {
            let firstChar: any = name.charAt(0);
            return firstChar + '. ' + name.split(' ').slice(1, name.split(' ').length).join(' ');
        } else {
            return name;
        }
    }


    if (!hippodrome || !agfData) {
        return <></>
    }


    let agfWagers: any = agfData.find((el: any) => el._date === hippodrome._date && el.keyword === hippodrome.keyword)


    if (!agfData || !agfWagers || (agfData.length > 0 && agfWagers && agfWagers.wagers.length === 0)) {
        return <>
            <div className="page-widget">
                <p className="p-4 text-center"><img alt={"clock"} src={iconClock} width={64}/> <br/><br/>
                    Henüz AGF verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
            </div>
        </>
    }


    return (<>
        <div className="page-widget">
            <svg style={{display: 'none'}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>
            <ul className="nav nav-tabs">
                {agfWagers.wagers.map((wager: any, keyRun: number) => {
                    return (
                        <li key={`runItem-${keyRun}`} className={`nav-item ${keyRun === shownWager ? 'active' : ''}`}>
                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                            <a className="nav-link" onClick={() => {
                                setShownWager(keyRun)
                                setShownMobileRun((keyRun * 5))
                            }}>{wager.wager}</a>
                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                        </li>)
                })}
            </ul>

            {isMobile && <div className="d-flex px-2 mt-2">
                {agfWagers.wagers[shownWager].runs.map((r: any, key: number) => {
                        return (
                            <button className="btn btn-sm btn-primary w-100 p-1" onClick={() => {
                                setShownMobileRun(key + (shownWager * 5))
                            }} style={{marginLeft: 1, marginRight: 1}}>{key + 1}.Ayak</button>
                        )
                    }
                )}
            </div>}

            <div className={"w-100 d-inline-flex flex-nowrap possibles-table"}>
                {agfWagers.wagers[shownWager].runs.map((r: any, key: number) => {

                    if (isMobile && shownMobileRun !== (key + (shownWager * 5))) {
                        return <></>
                    }

                    let runFalseHorses: any = [];
                    return (
                        <div className="col-12 col-md-2 p-1 runs" key={`agf-runs-r-${key}`}>
                            <div className="caption">
                                <div className={`text-center`}>{key + 1}. Ayak</div>
                            </div>
                            {r.horses.map((h: any, pkey: number) => {


                                let totalAgf = parseFloat(h.agf_ratio.replace(',', '.'));

                                if (h.stablemate !== null) {
                                    h.stablemate.map((s: any) => {
                                        if (s.run_status === false) {
                                            s.sstyle = eqStyles[h.stablemate[0].stablemate];
                                            runFalseHorses.push(s)
                                        }
                                    })
                                }

                                return (<div className="line py-1 d-flex" key={`agf-data-${pkey}`}>

                                    <div style={{width: 30}} className={`text-center`}>{h.agf_order}</div>
                                    <div className={`text-end `} style={{flex: "2"}}>

                    <span className="tooltip">{h.no} - {isMobile && h.name}
                        <div className="tooltip-text">{h.name}</div>

                    </span>(%{h.agf_ratio}) {h.stablemate !== null ?
                                        <img alt={"icon"} src={iconFather}
                                             className={`${false && h.run_status === false && 'black-to-red'}`}
                                             style={eqStyles[h.stablemate[0].stablemate]}/> : <></>} {h.run_status === false ? <><span
                                        className="text-danger">(K)</span></> : <></>}
                                        {h.stablemate !== null ? <>{h.stablemate.filter((c: any) => c.run_status === true).map((s: any, k: number) => {
                                            totalAgf += parseFloat(s.agf_ratio.replace(',', '.'))
                                            return (<span className={`stablemate tooltip`}
                                                          key={`item-agf-stablemate-${k}-${key}`}>
                        {s.no} - {isMobile && s.name} <b>(%{s.agf_ratio})</b> <img alt="F" src={iconFather}
                                                                                   className={`${false && s.run_status === false && 'black-to-red'}`}
                                                                                   style={eqStyles[h.stablemate[0].stablemate]}/> {s.run_status === false ? <>
                                                <span className="text-danger">(K)</span></> : <></>}
                                                <div className="tooltip-text">{s.name}</div>
                      </span>)
                                        })} </> : <></>}
                                    </div>
                                    {h.stablemate !== null && h.stablemate.length > 1 &&
                                        <div className="total-agf">{totalAgf.toFixed(2)}</div>}

                                </div>)

                            })}

                            {runFalseHorses.map((h: any, ssKey: number) => {

                                return (
                                    <div className={`line py-1 d-flex ${h.agf_order === 1 ? 'text-success' : ''}`}
                                         key={`agf-horses-${ssKey}`}>
                                        <div style={{width: 30}} className={`text-center`}>-</div>
                                        <div className={`text-left me-4`}>
                                            <span className="tooltip">{h.no}
                                                <div className="tooltip-text">{h.name}
                                                    <br/> Jokey: {getMinimalName(h.jockey)}</div></span>
                                            - (%{h.agf_ratio}) <img alt={"father"} src={iconFather}
                                                                    className={`${false && h.run_status === false && 'black-to-red'}`}
                                                                    style={h.sstyle}/> <span
                                            className="text-danger">(K)</span>
                                        </div>
                                    </div>
                                )

                            })}

                        </div>
                    )
                })}
            </div>

        </div>
    </>)
}
