import { BrowserRouter as Router, Link, Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import paths from "./paths";
import { Home } from "./pages/home/home";
import { Header } from "./shared/header/header";
import { Slider } from "./shared/slider/slider";
import { HippodromeWidget } from "./shared/hippodrome/hippodrome";
import { Footer, MobileFooter } from "./shared/footer/footer";
import { Betting, BettingRoot } from "./pages/betting/betting";
import React, { useEffect } from "react";
import { Register } from "./pages/register/register";
import { Login } from "./pages/login/login";
import { MemberInfo } from "./pages/member/member.info";
import { auth } from "./store/auth.store";
import { modalServiceSubject } from "./services/modal.service";
import { MemberWrapper } from "./pages/member/member.wrapper";
import { MemberTickets } from "./pages/member/member.tickets";
import { ChangePassword } from "./pages/member/member.password.change";
import { BankAccounts } from "./pages/member/member.bank.accounts";
import MemberTransactions from "./pages/member/member.transactions";
import { Results } from "./pages/results/results";
import { newsSubject, path } from "./store/misc.store";
import Deposit from "./pages/deposit/deposit";
import Withdraw from "./pages/withdraw/withdraw";
import { Coupons } from "./pages/tipster/coupons";
import Campaign from "./pages/campaigns/campaign";
import { Support } from "./pages/support/support";
import { Program } from "./pages/program/program";
import CampaignDetail from "./pages/campaigns/campaign-detail";
import { Stream } from "./pages/stream/stream";
import { Agreement } from "./pages/agreement/agreement";
import { Flatpage } from "./pages/flatpage/flatpage";
import { dateTimeFormat, useForceUpdate } from "./services/misc.functions";
import { news } from "./constants/global.vars";
import { Storage } from "./services/localstorege.service";
import { isMobile } from "./App";
import { MemberNotificationInfo } from "./pages/member/member.notification.info";
import { Notifications } from "./pages/notifications/notifications";
import { Comments } from "./pages/comments/comments";
import { JockeysStats } from "./pages/jockeys/jockeysStats";
import { HorseStats } from "./pages/jockeys/horseStats";
import Cookies from "./shared/cookies/cookies";
import { CookieSettings } from "./pages/member/member.cookie.settings";
import { Announcements } from "./pages/announcements/announcements";
import notFoundImage from "./assets/img/not-found.png";
import notFoundIcon from "./assets/icons/not-found-icon.svg";
import { mobileBetSlipActions, mobileBetSlipSubject } from "./services/betslip.service";
import Raffle from "./pages/raffles/raffle";
import { Games } from "./pages/games/games";
import RockPaperScissors from "./pages/games/rock-paper-scissors/rock.paper.scissors";
import SuperWhellGame from "./pages/games/wheel-game/super.wheel";
import ZeplinGameCards from "./pages/games/zeplin/zeplin-cards";
import { ScratchCards } from "./pages/games/scratch/scratch.cards";

export function PageNotFound() {
  return (
    <>
      <div className={"page-widget"} style={{ marginTop: "5rem" }}>
        <div className={"row"} style={{ minHeight: "75vh" }}>
          <div className={"col-4"}>
            <img
              alt={""}
              className={"position-absolute"}
              style={{
                top: "58px",
                height: "56%",
              }}
              src={notFoundImage}
            />
          </div>
          <div
            className={
              "col-8 py-5 d-flex flex-column justify-content-center align-items-center align-content-center text-center"
            }
          >
            <img alt={"notFound"} src={notFoundIcon} />
            <br />
            <h3>
              ÜZGÜNÜZ,
              <br />
              BU SAYFAYA ŞU AN ULAŞILAMIYOR :({" "}
            </h3>
            <br />
            <p>Tıkladığın bağlantı bozuk olabilir veya sayfa kaldırılmış olabilir.</p>
            <br />
            <Link to={paths.home.url} className="btn btn-info w-75">
              Anasayfa'ya Git
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

let metaTags: any = {};
Object.entries(paths).forEach(([key, value]: any) => {
  metaTags[value.url] = value;
});

const URLChangeHandler = () => {
  const { pathname } = useLocation();
  let currentPath = String(pathname);
  const [params] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    path.root = currentPath.split("/")[1] || "/";
    path.full = currentPath;

    try {
      let navItems: any = document.getElementsByClassName("navItem");
      for (let nav of navItems) {
        nav.classList.remove("active");
      }
      navItems = document.getElementsByClassName(path.root === "/" ? "anasayfa" : path.root);
      for (let nav of navItems) {
        nav.classList.add("active");
      }
    } catch (e) {}

    try {
      // @ts-ignore
      let metaTag: any = metaTags["/" + currentPath.split("/")[1]];

      document.title = metaTag.title;

      // @ts-ignore
      document.getElementsByTagName("meta").description.content = metaTag.description;
      // @ts-ignore
      document.getElementsByTagName("meta").keywords.content = metaTag.keywords;
    } catch (e) {
      console.log(e);
    }
    mobileBetSlipActions.isOpen = false;
    mobileBetSlipSubject.next("update");
  }, [currentPath]);

  return null;
};

export function ProtectedRoute({ children }: any) {
  if (!auth.member || !auth.token) {
    setTimeout(() => {
      modalServiceSubject.next({
        title: "Lütfen Giriş Yapınız",
        content: "Bu sayfayı görüntülemek için giriş yapmanız gerekmektedir.",
        width: 320,
        confirm: {
          cancel: {
            label: "Tamam",
          },
        },
      });
    }, 100);

    return <Navigate to={paths.login.url} />;
  }
  return children;
}

export function NewsHandler() {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let newsSubject_subscriber = newsSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      newsSubject_subscriber.unsubscribe();
    };
  }, []);

  if (news.length > 0) {
    let notSeen = news.filter((n: any) => n.is_seen !== true);
    if (notSeen.length > 0) {
      setTimeout(() => {
        modalServiceSubject.next({
          title: "Duyuru",
          content: `<div><h3 class="fs-5 fw-semibold mb-0">${notSeen[0].title}</h3>${notSeen[0].content}</div>`,
          width: 680,
          confirm: {
            cancel: {
              label: "Kapat",
            },
            sure: {
              label: "Tekrar Gösterme",
              class: "btn btn-warning",
              action: () => {
                notSeen[0].is_seen = true;
                Storage.set("news", news, 24 * 60 * 60 * 1000);
              },
            },
          },
        });
      }, 100);
    }
  }

  return <></>;
}

function AppRouters() {
  return (
    <>
      <Router>
        <URLChangeHandler />
        <NewsHandler />
        <Header />

        {/*<HippodromeWidget />*/}
        <div className="container mt-1 mt-lg-4">
          <Routes>
            <Route path={paths.home.url} element={<Home />} />
            <Route path={paths.verifyEmail.url} element={<Home />} />

            <Route path={paths.betting.url} element={<BettingRoot />} />
            <Route path={`${paths.betting.url}/:hippodrome`} element={<Betting />} />
            <Route path={`${paths.betting.url}/:hippodrome/:subpage`} element={<Betting />} />

            <Route path={paths.resetPassword.url} element={<Home />} />

            <Route path={paths.register.url} element={<Register />} />
            <Route path={paths.login.url} element={<Login />} />
            <Route path={`${paths.login.url}/:nextPage`} element={<Login />} />
            <Route path={paths.results.url} element={<Results />} />
            <Route path={`${paths.results.url}/:hippodrome/:run`} element={<Results />} />
            <Route path={paths.tipsterCoupons.url} element={<Coupons />} />
            <Route path={`${paths.tipsterCoupons.url}/:tab`} element={<Coupons />} />
            <Route path={paths.tipsterComments.url} element={<Comments />} />
            <Route path={paths.announcement.url} element={<Announcements />} />
            <Route path={paths.campaigns.url} element={<Campaign />} />
            <Route path={`${paths.campaigns.url}/:id`} element={<CampaignDetail />} />
            <Route path={paths.program.url} element={<Program />} />
            <Route path={paths.stream.url} element={<Stream />} />

            <Route path={`${paths.stream.url}/:tab`} element={<Stream />} />
            <Route path={`${paths.reportedJokeys.url}`} element={<JockeysStats />} />
            <Route path={`${paths.reportedJokeys.url}/:tab`} element={<JockeysStats />} />
            <Route path={`${paths.horseStats.url}`} element={<HorseStats />} />
            <Route path={`${paths.horseStats.url}/:tab`} element={<HorseStats />} />

            <Route path={paths.support.url} element={<Support />}>
              <Route path={":page"} element={<Support />} />
              <Route path={":page/:id/"} element={<Support />} />
            </Route>

            <Route path={paths.agreement.url} element={<Agreement />}>
              <Route path={":slug"} element={<Agreement />} />
            </Route>

            <Route path={`${paths.flatpage.url}/:slug`} element={<Flatpage />} />

            <Route
              path={`${paths.notifications.url}`}
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.memberInfo.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <MemberInfo />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.notificationInfo.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <MemberNotificationInfo />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.memberTickets.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <MemberTickets />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.memberSavedTickets.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <MemberTickets />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.changePassword.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <ChangePassword />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.memberBankAccounts.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <BankAccounts />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.memberTransactions.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <MemberTransactions />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.deposit.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <Deposit />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />
            <Route
              path={`${paths.withdraw.url}`}
              element={
                <ProtectedRoute>
                  <MemberWrapper>
                    <Withdraw />
                  </MemberWrapper>
                </ProtectedRoute>
              }
            />

            <Route path={paths.games.url} element={<Games />}></Route>

            <Route
              path={paths.scratch.url}
              element={
                <ProtectedRoute>
                  <ScratchCards />
                </ProtectedRoute>
              }
            >
              <Route
                path={":subpage"}
                element={
                  <ProtectedRoute>
                    <ScratchCards />
                  </ProtectedRoute>
                }
              />
              <Route
                path={":subpage/:draw_id/*"}
                element={
                  <ProtectedRoute>
                    <ScratchCards />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={paths.zeplin.url}
              element={
                <ProtectedRoute>
                  <ZeplinGameCards />
                </ProtectedRoute>
              }
            >
              <Route
                path={":subpage"}
                element={
                  <ProtectedRoute>
                    <ZeplinGameCards />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={paths.rockPaperScissors.url}
              element={
                <ProtectedRoute>
                  <RockPaperScissors />
                </ProtectedRoute>
              }
            >
              <Route
                path={":subpage"}
                element={
                  <ProtectedRoute>
                    <RockPaperScissors />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={paths.superWheel.url}
              element={
                <ProtectedRoute>
                  <SuperWhellGame />
                </ProtectedRoute>
              }
            >
              <Route
                path={":subpage"}
                element={
                  <ProtectedRoute>
                    <SuperWhellGame />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path={`${paths.cookieSettings.url}`} element={<CookieSettings />} />

            <Route path={paths.raffles.url} element={<Raffle />}></Route>
            <Route path={`${paths.raffles.url}/:slug`} element={<Raffle />} />
            <Route path={`${paths.raffles.url}/:draw_id/*`} element={<Raffle />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <Footer />

        {isMobile && <MobileFooter />}
        <Cookies></Cookies>
      </Router>
      <div id="backdrop-blur" className="backdrop-blur"></div>
    </>
  );
}

export default AppRouters;
