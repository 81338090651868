import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    bulletinProvider,
} from "../../services/bulletin.service";
import paths from "../../paths";
import {
    availableBettingSubTypes, availableBettingTypes,
    availableHippodromes,
    racesActionSubject
} from "../../store/bulletin.store";
import React, {useEffect, useState} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {Hippodrome, Run} from "../../models/bulletin.models";
import {BetSlip} from "../../shared/betslip/betslip";
import {
    betSlipActionSubject,
    mobileBetSlipActions,
    mobileBetSlipSubject,
    tjkBetSlipOptions,
    updateBetCount
} from "../../services/betslip.service";
import {BettingTypeOptions} from "./components/betting-options";
import {BettingDataTable} from "./components/betting-datatable";
import {BettingDataTabs} from "./components/betting-tabs";
import {Possibles} from "./components/betting-possibles";
import {AGF} from "./components/betting-agf";
import {isMobile} from "../../App";
import {TipstersComments} from "../home/tipsters.comments";
import {Stream} from "../stream/stream";
import TipsterTickets from "../home/tipster.tickets";
import {Coupons} from "../tipster/coupons";

export function BettingRoot() {

    const navigator = useNavigate();
    const forceUpdate = useForceUpdate();
    bulletinProvider.getAvailableHippodromes();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });


    useEffect(() => {
        let activeAvailableHippodromes = availableHippodromes.filter((el: any) => el.wager_details.length > 0);
        if (activeAvailableHippodromes.length > 0) {
            navigator(`${paths.betting.url}/${activeAvailableHippodromes[0].keyword.toLowerCase()}${activeAvailableHippodromes[0]._id}`);
        } else {
            navigator(`${paths.home.url}`);
        }

    }, [availableHippodromes.length]);


    return (<></>)
}

let rediretor: any = null;
let restored: any = null;

export function Betting() {

    const params: any = useParams();
    const forceUpdate = useForceUpdate();
    const navigator = useNavigate();
    const hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => {
        return hippodrome.keyword.toLowerCase() + hippodrome._id === params.hippodrome.toLowerCase()
    });
    const query = new URLSearchParams(useLocation().search);
    const sort = query.get("sort");
    const orijin = query.get("orijin");

    // const [mobileBetslip, setMobileBetslip] = React.useState<boolean>(false);

    //fixme gereksizleri sondür ya da tek sefer çalışsın
    bulletinProvider.getAvailableHippodromes();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {

                if (tjkBetSlipOptions.bettingType) {
                    bulletinProvider.getAvailableBettingTypes(hippodrome)
                    if (!availableBettingTypes.find((b: any) => b.id === tjkBetSlipOptions.bettingType.id)) {
                        if (availableBettingTypes.length > 0) {
                            bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
                            bulletinProvider.selectBettingColumn(1);
                        }
                    }
                    bulletinProvider.getAvailableBettingSubTypes(tjkBetSlipOptions.bettingType);
                    if (!availableBettingSubTypes.find((b: any) => b.id === tjkBetSlipOptions.bettingSubType.id)) {
                        if (availableBettingSubTypes.length > 0) {
                            bulletinProvider.selectBettingSubType(availableBettingSubTypes[0]);
                            bulletinProvider.selectBettingColumn(1);
                        }
                    }
                }

                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });
    useEffect(() => {
        let mobileBetSlipSubject_subscription = mobileBetSlipSubject.subscribe((res: any) => {
            if (res) {
                forceUpdate();
            }
        })
        return () => {
            mobileBetSlipSubject_subscription.unsubscribe();
        }
    });

    const selectBettingColumn = (no: any) => {
        bulletinProvider.selectBettingColumn(no);
        updateBetCount.status = true;
        forceUpdate();
    }

    const selectBettingSubType = (id: any) => {
        tjkBetSlipOptions.betSlipMultiply = 1;
        tjkBetSlipOptions.complete = false;
        betSlipActionSubject.next({action: 'complete'});
        bulletinProvider.selectBettingSubType(availableBettingSubTypes.find((el: any) => Number(el.id) === Number(id)));
        let tabs: any = [];
        for (let i = Number(tjkBetSlipOptions.bettingSubType.id); i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column; i += 1) {
            tabs.push(i)
        }
        selectBettingColumn(1);
    }

    const selectBettingType = (id: any) => {
        bulletinProvider.selectBettingType(hippodrome, id);
        selectBettingSubType(availableBettingSubTypes[0].id);
    }

    if (rediretor) {
        clearTimeout(rediretor);
    }

    if (!hippodrome) {
        rediretor = setTimeout(() => {
            window.location.href = paths.home.url;
        }, 3000);
        return (<></>)
    }

    if (!tjkBetSlipOptions.bettingType) {
        bulletinProvider.getAvailableBettingTypes(hippodrome)
        let isExist6li = availableBettingTypes.find((b: any) => b.id === 18)
        if (isExist6li) {
            bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
        } else {
            console.log(availableBettingTypes);
            if (availableBettingTypes.length > 0) {
                bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
            }
        }
        bulletinProvider.selectBettingColumn(1);
    }

    if (hippodrome && tjkBetSlipOptions.hippodrome?._id !== hippodrome._id) {
        tjkBetSlipOptions.betSlipMultiply = 1;
        tjkBetSlipOptions.usePointBalance = false;
        bulletinProvider.getAvailableBettingTypes(hippodrome);

        let isExist6li = availableBettingTypes.find((b: any) => b.id === 18)
        if (isExist6li) {
            bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
        } else {
            if (availableBettingTypes.length > 0) {
                bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
            }
        }
        bulletinProvider.selectBettingColumn(1);
    }


    tjkBetSlipOptions.hippodrome = hippodrome;

    // console.log("mobileBetslip::.",mobileBetslip)

    return (<>
        <BettingTypeOptions params={params} hippodrome={hippodrome}
                            selectBettingType={selectBettingType} selectBettingSubType={selectBettingSubType}/>

        <div className="row">

            {!params.subpage && <>

                {isMobile && mobileBetSlipActions.isOpen === true ? <></> :
                    <div className={`${hippodrome.wager_details.length > 0 ? "col-data" : 'col-12'}`}>
                        <div className={"page-widget px-4 mb-3 betting-navigation-tab d-none"}>
                            <div className={"row"}>
                                {/*  <div className={"col"}>
          <div className={`betting-navigation-item ${orijin === 'true' ? 'active' : ''}`}>
            <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}/?orijin=true`}>ORIJIN</Link>
          </div>
        </div>*/}
                                {/* <div className={"col"}>
          <div className={`betting-navigation-item ${sort === 'no' ? 'active' : ''}`}>
            <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}?sort=no`}>NO'YA GÖRE</Link>
          </div>
        </div>
        <div className={"col"}>
          <div className={`betting-navigation-item ${sort === 'agf' ? 'active' : ''}`}>
            <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}?sort=agf`}>AGF'YE
              GÖRE</Link>
          </div>
        </div>*/}
                                <div className={"col"}>
                                    <div
                                        className={`betting-navigation-item ${params.subpage === 'yorumlar' ? 'active' : ''}`}>

                                        <Link
                                            to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/yorumlar`}>YORUMLAR</Link>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div
                                        className={`betting-navigation-item ${params.subpage === 'tjk-tv' ? 'active' : ''}`}>
                                        <Link
                                            to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/tjk-tv`}>TJK
                                            TV</Link>
                                    </div>
                                </div>
                                <div className={"col-3"}>
                                    <div className={`betting-navigation-item ${sort === 'agf' ? 'active' : ''}`}>
                                        <Link
                                            to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/tjk-tv`}>SIRALA</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {hippodrome.wager_details.length > 0 && <div className="page-widget">
                            <svg style={{display: 'none'}}>
                                <defs>
                                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                                </defs>
                            </svg>
                            <BettingDataTabs selectBettingColumn={selectBettingColumn}/>

                            <BettingDataTable selectBettingColumn={selectBettingColumn}/>
                        </div>}


                        {hippodrome.wager_details.length === 0 &&
                            <div className={"page-widget"}>
                                <div className="hint-text text-center p-3" style={{minHeight: "50vh"}}>
                                    <p className={"fs-6 mb-0"}>Bu hipodromda açık koşu bulunmamaktadır.</p>
                                </div>
                            </div>}


                        {hippodrome.wager_details.length > 0 && <Hints></Hints>}

                        {hippodrome.wager_details.length > 0 && <div className={"page-widget mt-4"}>
                            <div className="hint-text p-3">
                                <p>Bu sayfadaki veriler TJK ’dan ve üçüncü taraf tedarikçi/veri sağlayıcılardan temin
                                    edilmiştir, bu
                                    bilgilerin doğruluğundan 4nala.com sorumlu değildir.</p>
                            </div>
                        </div>}
                    </div>}

                {isMobile && mobileBetSlipActions.isOpen === false ? <></> : <div className="col-betslip">
                    {tjkBetSlipOptions.bettingSubType && hippodrome.wager_details.length > 0 && <BetSlip/>}
                </div>}

            </>}

            {params.subpage === 'muhtemeller' && <>
                <div className="col-12">
                    <Possibles hippodrome={hippodrome}></Possibles>
                </div>
            </>}

            {params.subpage === 'agf' && <>
                <div className="col-12">
                    <AGF hippodrome={hippodrome}></AGF>
                </div>
            </>}

            {params.subpage === 'yorumlar' && <>
                <div className="col-12">
                    <div className="page-widget">
                        <svg style={{display: 'none'}}>
                            <defs>
                                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                            </defs>
                        </svg>
                        <ul className="nav nav-tabs">
                            <li className="nav-item active">
                                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use xlinkHref="#tabshape"></use>
                                </svg>
                                <a className="nav-link">UZMAN YORUMLARI</a>
                                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use xlinkHref="#tabshape"></use>
                                </svg>
                            </li>
                        </ul>

                        <div className="p-3"><TipstersComments
                            hippodrome={tjkBetSlipOptions.hippodrome}></TipstersComments></div>

                    </div>
                </div>
            </>}

            {params.subpage === 'hazir-kuponlar' && <>
                <div className="col-12">
                    <div className="page-widget">
                        <Coupons hippodrome={tjkBetSlipOptions.hippodrome}></Coupons>
                    </div>
                </div>
            </>}
            {params.subpage === 'tjk-tv' && <>
                <div className="col-12">

                    <Stream/>

                </div>
            </>}
        </div>


    </>)
}

export function Hints() {

    const [showHist, setShowHint] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(0)


    return (<div className="page-widget mt-4">
        <ul className="nav nav-tabs">
            <li className={`nav-item ${selectedTab === 0 ? "active" : ""}`} onClick={() => setSelectedTab(0)}>
                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                    <use xlinkHref="#tabshape"></use>
                </svg>
                <a className="nav-link">Nasıl Oynanır</a>
                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                    <use xlinkHref="#tabshape"></use>
                </svg>
            </li>
            <li className={`nav-item ${selectedTab === 1 ? "active" : ""}`} onClick={() => setSelectedTab(1)}>
                <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                    <use xlinkHref="#tabshape"></use>
                </svg>
                <a className="nav-link">YARDIM</a>
                <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                    <use xlinkHref="#tabshape"></use>
                </svg>
            </li>

        </ul>

        {selectedTab == 0 && <div className="hint-text p-3">
            <h3>{tjkBetSlipOptions.bettingType.name} nasıl oynanır?</h3>
            <p>
                {tjkBetSlipOptions.bettingType.info}
            </p>


        </div>}
        {selectedTab === 1 && <div className="hint-text p-3">
            <h3>At Yarışı Nedir? At Yarışı Oyunu Nasıl Oynanır?</h3>
            <p>At Yarışı (TJK), Türkiye Jokey Kulübü tarafından düzenlenen ve resmi yarış programına göre oynatılan
                müşterek
                bir bahis oyunudur. Bu oyun, yarış sonuçlarının tahmin edilmesi üzerine kuruludur. Tarihi oldukça eski
                dönemlere
                dayanan bu oyun, hem eğlence hem de bahis amaçlı oynanır. Bu yazıda, at yarışının ne olduğunu, tarihini,
                bahis
                türlerini, altılı sonuçları ve altılı ganyanın nasıl oynandığını ayrıntılı olarak ele alacağız.</p>
            {!showHist && <a className="cursor-pointer link" onClick={() => {
                setShowHint(true);
            }}>Devamını Oku</a>}

            {showHist && <>
                <h3>At Yarışı Nedir? </h3>
                <p>
                    Gazi Mustafa Kemal Atatürk'ün ifade ettiği gibi, at yarışı modern toplumlar için bir sosyal
                    ihtiyaçtır. Peki,
                    at yarışı nedir ve nasıl bu kadar popüler hale gelmiştir? At yarışı, insanlık tarihinin bilinen en
                    eski spor
                    dallarından biri olarak kabul edilmektedir.
                </p>
                <p>
                    At yarışları, 1750 yılında İngiltere'de Newmarket'ta kurulan Jokey Kulübü tarafından organize
                    edilmeye
                    başlanmıştır.
                </p>

                <p>
                    Fransa'da, Jokey Kulübü Büyük Ödülü, Paris Büyük Ödülü ve Zafer Takı Büyük Ödülü gibi prestijli
                    yarışlar
                    düzenlenmektedir. Bu yarışlar, Fransa'nın at yarışı geleneğinde önemli bir yer tutar ve uluslararası
                    alanda
                    büyük ilgi görmektedir. Avustralya'da ise en ünlü yarış Melbourne Kupa Koşusu'dur. Dünya Binicilik
                    Şampiyonası
                    ise ilk kez 1970 yılında düzenlenmiştir.
                </p>
                <p>
                    At yarışlarının daha heyecan verici bir spor haline gelmesinde etkili olan faktörlerden biri de
                    bahislerdir.
                    At yarışı, resmi programlar dahilinde oynanan ve oldukça heyecan verici bir bahis oyunudur. Önceden
                    belirlenmiş olan ikramiyeler, başarılı bahis yapan kişiler arasında bölüştürülür. Atların koşu
                    stillerini
                    tanımak, oluşturulan bahis kuponunun başarılı olmasını sağlayabilir.
                </p>
                <p>
                    At yarışı, düşük bütçelerle bile oynanabilen bir oyun olup, küçük bir miktarla büyük ödüller kazanma
                    imkanı
                    sunduğundan çekiciliğini korumaktadır. Bu yazıda, at yarışı bahis türleri ve altılı yarış tahminleri
                    gibi
                    konulara da değineceğiz.
                </p>
                <h3>At Yarışında Bahis Türleri</h3>
                <p>Türkiye'de at yarışı, oldukça yaygın bir etkinlik olup, bahis oyunları arasında önemli bir yer
                    tutmaktadır. Türkiye Jokey Kulübü (TJK) tarafından düzenlenen yarış programları doğrultusunda, en
                    çok oynanan bahis türü altılı ganyandır. Bunun yanı sıra, at yarışı kuponları bakımından birçok
                    alternatif bahis türü bulunmaktadır.</p>

                <p>TJK yarış programı, at yarışı bahis türleri açısından önemli bir kılavuz niteliğindedir. Altılı yarış
                    tahmini, bu oyunun en önemli parçası olmasına rağmen, diğer bahis türleri de bahisçilere çeşitli
                    kazanç fırsatları sunmaktadır.</p>

                <p>Oyunlar arasında 5'li Ganyan, 4'lü Ganyan, 3'lü Ganyan, Ganyan, Çifte Bahis, Sıralı İkili, Sırasız
                    İkili, Sıralı Üçlü Bahis, Tabela Bahis, Tabela Bahis (Virgüllü), Plase, 7'li Plase, Plase 2'li,
                    Sıralı Beşli Bahis, Sıralı Beşli Bahis (Virgüllü), Ganyan & Plase bulunmaktadır. Tüm oyunlardan en
                    düşük bahis tutar 10 TL olarak Türkiye Jokey Kulübü tarafından belirlenen bir kuraldır.</p>

                <h3>At Yarışının Temel Kuralları</h3>
                <p>At yarışlarının temel kuralları oldukça kapsamlı olup, bu alanda bahis yapmak teknik bilgi birikimi
                    gerektirir. Yarış terimlerini ve anlamlarını iyi bilmek, kısaltmaları doğru takip etmek bu süreçte
                    büyük önem taşır. Türkiye Jokey Kulübü (TJK) tarafından çıkarılan resmi programa göre oynanan at
                    yarışları, müşterek bahisler için belirlenen kurallara tabidir. Daha ayrıntılı bilgiler, at yarışı
                    genel hükümleri ve her yıl güncellenen at yarışı genel yönetmeliklerinde yer almaktadır.</p>

                <h3>At Yarışı Nereden Oynanır?</h3>
                <p>At yarışı kupon yapmak günümüzde en ideal yöntem sanal bayilerdir. 4nala.com'dan yasal ve güvenilir
                    şekilde at yarışı bahislerinizi oynayabilirsiniz.</p>

                <h3>At Yarışı Tahmini Nasıl Yapılır?</h3>
                <p>At yarışı tahmini ve altılı hazır kupon oluşturma, teknik bilgi ve dikkat gerektiren bir süreçtir.
                    4nala.com, geniş uzman yazar kadrosuyla altılı yarış tahminleri konusunda yarışseverlere kapsamlı
                    bilgiler sunmaktadır. At yarışlarının karakteristik özelliklerinden biri, sürpriz atların
                    beklenmedik başarılarıdır; bu nedenle, tahmin yaparken sürpriz atlara odaklanmak önemlidir.</p>


                <h3>At Yarışı Oyunlarında Taktikler ve İpuçlarının Önemi</h3>

                <p>At yarışı oyunlarında başarı için taktikler ve ipuçları büyük önem taşır. Banko, plase ve sürpriz
                    yapacak atları belirlemek, oyuncuların kazanma şansını artıran kritik bir stratejidir. Bu noktada,
                    4nala.com'un uzman yazarlarını takip etmek en kolay ve etkili yöntemdir.</p>

                <p>Bahis kuponlarına eklenen atların önceki performansları titizlikle incelenmelidir. Atın son beş
                    yarışı değil, genel performansı dikkate alınmalıdır. Ne kadar doğru analiz yapılırsa yapılsın, bahis
                    yaparken bütçeyi aşmamak önemlidir. Bilinçli bir bütçe yönetimi, uzun vadede başarıyı sürdürmenin
                    anahtarıdır.</p>
                <p>At yarışı oyunlarında başarı için taktikler ve ipuçlarını dikkate almak, oyuncuların kazanma şansını
                    artırır ve daha bilinçli bahisler yapmalarını sağlar. Bu nedenle, at yarışı tahminlerinde detaylı
                    bir analiz ve stratejik bir yaklaşım önemlidir.</p>
                <h3>At Yarışı Sonuçları Nereden Takip Edilir?</h3>
                <p>
                    At yarışı resmi sonuçları ve Altılı Ganyan sonuçları, Türkiye Jokey Kulübü (TJK) tarafından
                    açıklanmaktadır. 4nala.com üzerinden de Altılı Ganyan sonuçları takip edilebilmektedir. Altılı
                    Ganyan sonuçları, son ayak koşulduktan sonra genellikle 20-25 dakika içinde duyurulmaktadır.
                </p>
                <h3>Altılı Ganyan Sonuçları</h3>
                <p>
                    Altılı Ganyan sonuçları, yarışseverlerin en çok merak ettiği konulardan biridir. TJK, bu sonuçları
                    canlı olarak duyurur ve kazananların ne kadar kazandığı ve dağıtılacak miktarlar hakkında bilgi
                    sağlar. Eğer Altılı Ganyan'da kazanan çıkmazsa ve ikramiye devredilirse, toplanan tevzi miktarı TJK
                    tarafından belirlenen başka bir yarış programına aktarılmaktadır.
                </p>
                <h3>Sonuçların Takibi</h3>
                <p>
                    At yarışı sonuçlarını anlık olarak 4nala.com'dan takip edebilirsiniz. Bu platform, yarışseverlere
                    hızlı ve güvenilir bilgi akışı sağlayarak, bahislerin sonuçlarını anında öğrenme imkanı sunmaktadır.
                </p>
                <p>
                    Sonuç olarak, at yarışı ve Altılı Ganyan sonuçlarını takip etmek isteyen yarışseverler, TJK'nın
                    resmi duyurularını ve 4nala.com'u kullanarak güncel bilgilere ulaşabilirler. Bu sayede, yarış
                    sonuçları ve kazançlar hakkında anında bilgi sahibi olabilirsiniz.
                </p>
                {showHist && <a className="cursor-pointer link" onClick={() => {
                    setShowHint(false);
                }}>Gizle</a>}

            </>}


        </div>}


    </div>)
}
