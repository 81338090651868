import "./ticket.css";
import { ServerDate, useForceUpdate } from "../../../services/misc.functions";
import React, { useEffect } from "react";
import { cartStore, cartToggleSubject, raffleDraws, raffleDrawSubject } from "../../../store/raffle.store";
import { addToCart, openRaffleDetail, removeFromCart, updateCart } from "../../../services/raffle.service";
import { isMobile } from "../../../App";

export default function Ticket(props: any) {
  const forceUpdate = useForceUpdate();
  const ticket = props.item;
  const showBuyButton = props.showBuyButton ?? true;

  useEffect(() => {
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe((res: any) => {
      if (
        (res && res.item && res.item.item && res.item.item.id === props.item.id) ||
        (res.list && res.list.includes(props.item.id))
      ) {
        forceUpdate();
      }
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let cartToggleSubject_subscriber = raffleDrawSubject.subscribe((res: any) => {
      if (res.action == "update" && res.id == props.item.id) {
        forceUpdate();
      }
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  const leftDays = () => {
    try {
      let leftDay = Math.floor(
        (new Date(ticket.draw_date.split("T")[0]).getTime() - ServerDate().setHours(0, 0, 0, 0)) / 1000 / 60 / 60 / 24
      );
      if (leftDay == 0) {
        return "Bugün";
      } else {
        return leftDay;
      }
    } catch (e) {
      return Math.floor((new Date(ticket.draw_date).getTime() - ServerDate().getTime()) / 1000 / 60 / 60 / 24);
    }
  };

  const leftTicketPerc = () => {
    let perc = 100 - (100 * ticket.remaining_tickets) / ticket.total_tickets;
    if (ticket.remaining_tickets === 0) {
      perc = 100;
    } else {
      if (perc > 99) {
        perc = 99;
      }
    }

    return perc.toFixed(0);
  };

  const leftTicketPercColor = () => {
    let perc = 100 - (100 * ticket.remaining_tickets) / ticket.total_tickets;
    if (perc < 50) {
      return "#4AD3A5";
    } else if (perc < 80) {
      return "#FF9F46";
    } else {
      return "#FF4646";
    }
  };

  return (
    <div className="ticket-card pointer">
      <div className="head">
        <div className="flags py-2">
          {ticket && ticket.title_abbreviated && !ticket.title_abbreviated.toLowerCase().includes("çoklu eşya") ? (
            <>
              {ticket.draw_price && ticket.draw_price.length > 0 ? ticket.draw_price[0].price_count : "0"}
              <br />
              Adet
            </>
          ) : (
            <>
              <br />
            </>
          )}
        </div>

        <div className="circle-sold">
          <div>
            <div
              className="pie animate"
              style={
                {
                  "--w": "60px",
                  "--p": leftTicketPerc(),
                  "--b": "7px",
                  "--c": `${leftTicketPercColor()}`,
                } as React.CSSProperties
              }
            >
              {leftTicketPerc()}%<span>SATILAN</span>
            </div>
          </div>
        </div>

        <div className="timer">
          <span>
            {leftDays()} <br />
            {leftDays() != "Bugün" && "Gün"}
          </span>
        </div>
      </div>
      <div
        className={`body ${isMobile && "mobile-ticket"}`}
        onClick={() => {
          openRaffleDetail(ticket);
        }}
      >
        <div className="flags">
          {ticket.is_new && <span className="px-2 text-white badge rounded-pill bg-primary">⭐ Yeni</span>}
          {ticket.is_popular && <span className="px-2 text-white badge rounded-pill bg-warning">🔥 Popüler</span>}
          {ticket.best_selling && <span className="px-2 text-white badge rounded-pill bg-warning">Çok Satan</span>}
          {ticket.second_hand && <span className="px-2 text-white badge rounded-pill bg-secondary">2.El</span>}
          {ticket.is_running_out && <span className="px-2 text-white badge rounded-pill bg-success">💥 Tükeniyor</span>}
        </div>

        <img src={ticket.photo} />
        <h2 className="mt-2">{ticket?.title_abbreviated}</h2>
      </div>
      {showBuyButton && (
        <div className="foot">
          {ticket.is_added && (
            <div className="add">
              <div className="input">
                <i
                  onClick={() => {
                    if (cartStore.items[ticket.cartIndex] && cartStore.items[ticket.cartIndex]?.quantity > 0) {
                      updateCart(
                        cartStore.items[ticket.cartIndex],
                        ticket.cartIndex,
                        Number(cartStore.items[ticket.cartIndex]?.quantity) - 1
                      );
                    }
                  }}
                >
                  -
                </i>
                <input
                  className="no-arrows"
                  type="number"
                  value={cartStore.items[ticket.cartIndex]?.quantity || ""}
                  onChange={(e: any) => {
                    updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, e.target.value);
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value === "" || e.target.value === "0") {
                      removeFromCart(cartStore.items[ticket.cartIndex], ticket.cartIndex);
                    }
                  }}
                  max={100}
                  step={1}
                  min={1}
                />
                <i
                  onClick={() => {
                    if (
                      cartStore.items[ticket.cartIndex] &&
                      cartStore.items[ticket.cartIndex]?.quantity < 100 &&
                      cartStore.items[ticket.cartIndex]?.quantity < ticket.remaining_tickets
                    ) {
                      updateCart(
                        cartStore.items[ticket.cartIndex],
                        ticket.cartIndex,
                        Number(cartStore.items[ticket.cartIndex].quantity) + 1
                      );
                    }
                  }}
                >
                  +
                </i>
              </div>
              <div className="price text-end">
                {cartStore.items[ticket.cartIndex] && (
                  <span>
                    {Number(
                      ticket.ticket_price *
                        (Number(cartStore.items[ticket.cartIndex].quantity) != 0
                          ? Number(cartStore.items[ticket.cartIndex].quantity)
                          : 1)
                    ).toFixed(2)}
                    ₺
                  </span>
                )}
              </div>
            </div>
          )}

          <>
            {ticket.is_added !== true && ticket.remaining_tickets > 0 && (
              <button
                onClick={() => {
                  addToCart(ticket, 1, "raffle");
                }}
                className="btn btn-add-to-cart text-white w-100 rounded-pill"
              >
                Bilet Al
                <span className="price">{Number(ticket.ticket_price).toFixed(2)}₺</span>
              </button>
            )}

            {ticket.remaining_tickets === 0 && (
              <button className="btn fw-bold w-100 text-center btn-secondary rounded-pill">Tükendi</button>
            )}
          </>
          {/* )} */}
        </div>
      )}
    </div>
  );
}
