import React, { useEffect, useState } from "react";
import { dateFormat, loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import { useLocation } from "react-router-dom";
import { modalServiceSubject } from "../../services/modal.service";
import { isMobile } from "../../App";
import Modal from "react-modal";
import { ApiService } from "../../services/http.service";
import { env, months } from "../../constants/global.vars";

let years: any = [2021, 2022, 2023, 2024, 2025, 2026, 2027];
let selectedYear: any = new Date().getFullYear();
let selectedMonth: any = new Date().getMonth();
let availableWeeks: any = null;

export function RaffleResults() {
  const api = new ApiService();
  const { pathname } = useLocation();
  const [dataList, setDataList] = useState<any[]>([]);
  const [dateList, setDateList] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [openRaffleResultDetail, setOpenRaffleResultDetail] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const forceUpdate = useForceUpdate();

  const getRaffleData = () => {
    if (selectedDate) {
      let payload: any = {
        // year: selectedYear,
        // month: selectedMonth + 1,
        // draw_date: selectedDate,
      };
      if (isMobile) {
        payload = {
          draw_date: selectedDate,
        };
      } else {
        payload = {
          year: selectedYear,
          month: selectedMonth + 1,
          draw_date: selectedDate,
        };
      }
      api
        .start("post", env.accounting_api + "/somos/api/goods-lottery/list-draw-result/", payload, false)
        .then((res: any) => {
          setDataList(res.data);
        });
    }
  };

  useEffect(() => {
    loadData();
  }, [pathname, selectedYear, selectedMonth, selectedDate]);

  useEffect(() => {
    selectedYear = new Date().getFullYear();
    selectedMonth = new Date().getMonth();
    availableWeeks = null;
    // getRaffleData();
  }, [pathname]);

  const loadData = async (forcePage: any = null) => {
    loadingAnimation(true);
    setLoading(true);
    let payload: any = {
      // year: selectedYear,
      // month: selectedMonth + 1,
      // draw_date: selectedDate,
    };
    if (isMobile) {
      payload = {
        draw_date: selectedDate,
      };
    } else {
      payload = {
        year: selectedYear,
        month: selectedMonth + 1,
        draw_date: selectedDate,
      };
    }
    // console.log(payload)
    api
      .start("post", env.accounting_api + "/somos/api/goods-lottery/list-draw-dates/", payload, false)
      .then((res: any) => {
        loadingAnimation(false);
        setLoading(false);
        if (isMobile) {
          setDateList(res.data);
          if (selectedDate == null) {
            setSelectedDate(res.data[0].draw_date);
          }
        } else {
          if (selectedDate === null) {
            availableWeeks = Array.from(new Set(res.data.map((date: any) => date.draw_date)));
          }
        }
      })
      .finally(() => {
        if (!isMobile) {
          if (availableWeeks?.length > 0) {
            if (selectedDate == null) {
              setSelectedDate(availableWeeks[0]);
            }
            getRaffleData();
          }
        } else {
          getRaffleData();
        }
      });
  };

  const yearChangeHandler = (year: any) => {
    // console.log(year);
    setSelectedDate(null);
    selectedYear = Number(year);
    forceUpdate();
  };
  const monthChangeHandler = (month: any) => {
    setSelectedDate(null);
    selectedMonth = Number(month);
    forceUpdate();
  };
  const showWinners = (item: any) => {
    modalServiceSubject.next({
      title: `Kazanan Biletler - ${item.DrawName ?? ""}`,
      content: `<p>
            <div class="row mx-0">
                ${item.Winner.map((c: any) => {
                  return `<div class="col-3 p-1 text-center"><div class="border  rounded-2 bg-success-subtle  border-success">${c.winner_id}</div></div>`;
                }).join("")}
            </div>
          </p>`,
      confirm: {
        cancel: {
          label: "Kapat",
          class: "btn btn-secondary w-100",
        },
      },
    });
  };

  const loadMore = (e: any) => {
    e.target.disabled = true;
    loadData().finally(() => {
      e.target.disabled = false;
    });
  };
  return (
    <>
      <div className="draw-filter-wrapper-column w-auto mx-0 mb-2">
        {!isMobile && <h4>Tarih filtresi</h4>}
        {isMobile ? (
          <div className={"row"}>
            <div className={"col-12"}>
              <select
                style={{ fontSize: "12px" }}
                className="form-control text-center date-result-select w-100"
                value={selectedDate}
                onChange={(e: any) => {
                  setSelectedDate(e.target.value);
                }}
              >
                {dateList.map((el: any, key: any) => {
                  return (
                    <option key={key + el.draw_date} value={el.draw_date}>
                      {dateFormat(el.draw_date)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ) : (
          <div className="row mt-2">
            <div className="col-4">
              <select
                style={{ fontSize: "12px" }}
                className="form-control text-center w-100"
                value={selectedYear}
                onChange={(e: any) => {
                  yearChangeHandler(e.target.value);
                }}
              >
                {years
                  .filter((el: any) => el <= Number(new Date().getFullYear()))
                  .map((el: any) => {
                    return (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-4">
              <select
                style={{ fontSize: "12px" }}
                value={selectedMonth}
                className="form-control text-center w-100"
                onChange={(e: any) => {
                  monthChangeHandler(e.target.value);
                }}
              >
                {months
                  .filter((el: any) => {
                    if (Number(selectedYear) === Number(new Date().getFullYear())) {
                      if (Number(el.val) - 1 <= new Date().getMonth()) {
                        return el;
                      }
                    } else if (Number(selectedYear) !== Number(new Date().getFullYear())) {
                      return el;
                    }
                  })
                  .map((el: any, i: number) => {
                    return (
                      <option key={`months-select-${i}`} value={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-4">
              <select
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  fontSize: "12px",
                }}
                className="form-control text-center w-100"
                onChange={(e: any) => {
                  if (e.target.value !== "-1") {
                    setSelectedDate(e.target.value);
                  } else {
                    setSelectedDate(null);
                  }
                }}
              >
                {availableWeeks &&
                  availableWeeks.map((el: any, i: number) => {
                    return (
                      <option key={`date-select-${i}`} value={el}>
                        {dateFormat(el + "T00:00:00")}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        )}
      </div>

      <div className={`my-raffle-order-item`}>
        {dataList.map((el: any, key: number) => {
          return !isMobile ? (
            <div key={`raffle-result-${key}`} className="row draw-item m-3">
              <div className="col-4 p-1">
                <img className="draw-image" src={`${env.cdn_host}/media/${el.DrawPicture}`} />
              </div>
              <div className="col-4 p-2 border-end">
                <div className="fw-bold">{el.DrawName}</div>
                <span className="d-block text-muted">Çekiliş Tarihi: {dateFormat(el.DrawDate)}</span>
                <span className="d-block text-muted">Çekiliş No: {el.DrawNo}</span>
              </div>
              <div className="col-4 p-2">
                <span className="d-block fw-bold">Kazanan Biletler</span>
                <div className="row mx-0">
                  {el.Winner.map((winner: any, wkey: number) => {
                    return (
                      <div
                        key={`winner-${wkey}`}
                        className={"col-4 text-center " + (wkey === 2 || wkey === 5 ? "pe-0 ps-1" : "px-1")}
                      >
                        <div className="border p-1 mb-2 rounded-2 border">{winner.winner_id}</div>
                      </div>
                    );
                  })}
                </div>
                {el.Winner?.length > 6 && (
                  <span
                    className="d-block text-end link-primary pointer"
                    onClick={() => {
                      showWinners(el);
                    }}
                  >
                    Tüm Kazanan Biletleri Gör
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div key={`raffle-result-${key}`} className="row item ">
              <div className="col-4 column-center">
                <div className={"p-2"}>
                  <img className="img-thumbnail" src={`${env.cdn_host}/media/${el.DrawPicture}`}/>
                </div>

                {/*<h3 className="text-center">{el.DrawName}</h3>*/}
                <a  className={"text-center pointer"} onClick={() => {
                    setOpenRaffleResultDetail(el);
                }}>
                    Çekiliş Detayları
                </a>
              </div>
              <div className="col-8">
                <span style={{ fontSize: "16px", fontWeight: "700",lineHeight:"1" }}>{el.DrawName}</span>
                <span className="d-block mt-2">
                  <span className={"text-black-50"}>Çekiliş Tarihi:</span>
                  <span className={"fw-bold text-primary"}>{dateFormat(el.DrawDate)}</span>
                </span>
                <span className="d-block">
                  <span className={"text-black-50"}>Çekiliş No:</span>{" "}
                  <span className={"fw-bold text-primary"}>{el.DrawNo}</span>
                </span>
                <div className={"d-flex justify-content-between mt-2 align-items-center"}>
                  {/*<span*/}
                  {/*  className="d-block text-decoration-underline text-info "*/}
                  {/*  onClick={() => {*/}
                  {/*    setOpenRaffleResultDetail(el);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Çekiliş Detayları*/}
                  {/*</span>*/}
                  {el.Winner?.length > 0 && (
                    <span
                      className="d-block text-end link-primary pointer"
                      onClick={() => {
                        showWinners(el);
                      }}
                    >
                      Kazanan Biletleri Gör
                    </span>
                  )}
                </div>

                {/*<div className="row mx-0 justify-content-start">*/}
                {/*    {el.Winner.map((winner: any, wkey: number) => {*/}
                {/*        return (*/}
                {/*            <div*/}
                {/*                key={`winner-${wkey}`}*/}
                {/*                className={*/}
                {/*                    "col-6 text-center " +*/}
                {/*                    (wkey === 2 || wkey === 5 ? "pe-0 ps-1" : "px-1")*/}
                {/*                }*/}
                {/*            >*/}
                {/*                <div*/}
                {/*                    className="border p-1 mb-2 rounded-2 bg-success-subtle border-success">*/}
                {/*                    {winner.winner_id}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</div>*/}
              </div>
            </div>
          );
        })}
      </div>

      {dataList && dataList?.length === 0 && !loading && (
        <>
          <div className="text-center py-5">Girdiğiniz kriterlerde kayıt bulunamadı.</div>
        </>
      )}
      <div className="text-center">
        <div id="id_loaderAnimation" className="loader"></div>
        <button
          id="id_datalist_loadmore"
          onClick={loadMore}
          type="button"
          className="btn rounded-pill btn-outline-primary"
        >
          Devamını Yükle
        </button>
      </div>

      <Modal
        isOpen={openRaffleResultDetail !== null}
        id={"id_detail_wrapper"}
        className={"default draw-detail-result-modal"}
        onRequestClose={() => {
          setOpenRaffleResultDetail(null);
        }}
        contentLabel=""
      >
        {!isMobile && (
          <div className={"modal-close"}>
            {/* <img
              src={iconClose}
              width={24}
              className={"float-right cursor-p"}
              onClick={() => setOpenRaffleResultDetail(null)}
            /> */}
          </div>
        )}

        <div className={"draw-detail-wrapper"}>
          {/* <img
            className={`${openRaffleResultDetail ? "btnBackFixed" : ""} btnBack`}
            src={iconBackArrow}
            onClick={() => {
              setOpenRaffleResultDetail(null);
            }}
          /> */}

          {openRaffleResultDetail && (
            <>
              <div className="body">
                <h2 className="draw-title">{openRaffleResultDetail.DrawName}</h2>
                <p className="draw-name mb-2 mt-2">{openRaffleResultDetail.DrawTitle}</p>
                <div className="row mx-0">
                  {openRaffleResultDetail.Winner.map((c: any) => {
                    return `<div class="col-3 p-1 text-center"><div class="border  rounded-2 bg-success-subtle  border-success">${c.winner_id}</div></div>`;
                  }).join("")}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
