import "./withdraw.css";
import React, { useEffect, useState } from "react";
import infoIcon from "../../assets/icons/info.svg";
import { toast } from "react-toastify";
import { env } from "../../constants/global.vars";
import { auth, authSubject } from "../../store/auth.store";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApiService } from "../../services/http.service";
import {CurrencyFormat, preventNegativeValues, useForceUpdate} from "../../services/misc.functions";
import _ from "lodash";
import plusIcon from "../../assets/feather/plus-circle.svg";
import { useNavigate } from "react-router-dom";
import {isMobile} from "../../App";
import {modalServiceSubject} from "../../services/modal.service";

export default function Withdraw() {
  const navigate = useNavigate();
  const [accountList, setAccountList] = useState<any>(null);
  const [withdrawList, setWithdrawList] = useState<any>([]);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const api = new ApiService();
    getWithdrawHistory();

    api
      .start("get", env.accounting_api + "/member/api/list-account/", null, true)
      .then((res: any) => {
        if (res && res.status) {
          setAccountList(_.orderBy(res.data, ["id"], ["desc"]));
        } else {
          setAccountList([]);
        }
      })
      .catch((e: any) => {
        // exceptionModal(e);
        setAccountList([]);
      });
  }, []);

  const getWithdrawHistory = () => {
    const api = new ApiService();
    api.start("get", env.accounting_api + "/accounting/api/list-withdraw/", null, true).then((res: any) => {
      // console.log(res)
      if (res && res.data) {
        setWithdrawList(res.data);
      }
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = (data: any) => {
    const api = new ApiService();

    if (!auth.member || !auth.member?.id) {
      return toast.error("Lütfen giriş yapınız.");
    }

    if (data.amount === 0) {
      return toast.error("Çekim tutarı 0 olamaz.");
    }

    if (Number(auth.member.balance_debit_available) < Number(data.amount)) {
      return toast.error("Yetersiz Bakiye. Lütfen çekilebilir bakiyenizi kontrol ediniz.");
    }

    let id_submit_withdraw: any = document.getElementById("id_submit_withdraw");
    if (id_submit_withdraw) {
      id_submit_withdraw.disabled = true;
    }

    api
      .start("post", env.accounting_api + "/accounting/api/create-withdraw-request/", data, true)
      .then((res: any) => {
        if (res && res.status) {
          getWithdrawHistory();
          toast.success(res.message);
          reset({ account_id: -1, amount: "", sure: false });
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        id_submit_withdraw.disabled = false;
      });
  };

  console.log(accountList);

  return (
    <>
      <h1>Para Çek</h1>
      <div className="page-widget">
      <div className="p-3">
      { isMobile ? <div className={"withdraw-widget"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <div className={"row"}>
                  <div className={"col-5"}><p className={"text-center fs-7"}>ÇEKİLEBİLİR TUTAR</p></div>
                  <div className={"col-4"}><p
                      className="fw-bold text-center">{CurrencyFormat(auth.member.balance_debit_available)} TL</p></div>
                  <div className={"col-3 text-center"}>
                    <img src={infoIcon} alt=""  onClick={()=>{
                      modalServiceSubject.next({
                        case: 'info',
                        width: '400px',
                        title: 'ÇEKİLEBİLİR TUTAR',
                        content: `<p class="text-center"></p>`,
                        confirm: {

                          cancel: {
                            label: 'Kapat',
                            class: 'btn-secondary w-100',
                          }
                        }
                      });
                    }} />
                  </div>
                </div>
              </div>
              <div className={"col-12"}>
                <div className={"row"}>
                  <div className={"col-5"}><p className={"text-center fs-7"}>ÇEKİLEMEZ TUTAR</p></div>
                  <div className={"col-4"}><p
                      className="fw-bold text-center">{CurrencyFormat(Number(auth.member.balance_point) )} TL</p></div>
                  <div className={"col-3 text-center"}>
                    <img src={infoIcon} alt="" onClick={()=>{
                      modalServiceSubject.next({
                        case: 'info',
                        width: '400px',
                        title: 'ÇEKİLEMEZ TUTAR',
                        content: `<p class="text-center">Çekilemez Bakiye Nedir ve Nasıl Kullanılır?<br/>
                                  ATM'lerin kartsız işlem kanalını kullanarak 4nala.com hesabınıza yatırdığınız tutarlar ile 4nala.com’un çeşitli faaliyetlerinden elde edilen hediye puanlarınızın toplamı, 4nala.com’daki çekilemez bakiyenizdir.
                                  <br/>
                                  Çekilemez bakiyelerinizi 4nala.com’da ki kupon harcamalarında kullanabilirsiniz. At yarışı kuponlarından elde edilen kazancınızı ise “PARA ÇEKME” işlemiyle anında banka hesabınıza nakit olarak aktarabilirsiniz.
                                  <br/>
                                  Çekilemez bakiyenizi kullanmak için ayrıca bir işlem yapmanıza gerek bulunmamaktadır. Sitemiz üzerinde oynadığınız tüm kupon harcamalarında, kupon bedeli, öncelikle çekilemez bakiye alanındaki tutarınızdan otomatik olarak düşer.</p>`,
                        confirm: {
                          cancel: {
                            label: 'Kapat',
                            class: 'btn-secondary w-100',
                          }
                        }
                      });
                    }}/>
                  </div>
                </div>
              </div>
              <div className={"col-12"}>
                <div className={"row"}>
                  <div className={"col-5"}><p className={"text-center fs-7"}>TOPLAM BAKİYE</p></div>
                  <div className={"col-4"}><p
                      className="fw-bold text-center">{CurrencyFormat(Number(auth.member.balance_debit) + Number(auth.member.balance_point))} TL</p>
                  </div>
                  <div className={"col-3 text-center"}>
                    <img src={infoIcon} alt="" onClick={() => {
                      modalServiceSubject.next({
                        case: 'info',
                        width: '400px',
                        title: 'TOPLAM BAKİYE',
                        content: `<p class="text-center"></p>`,
                        confirm: {

                          cancel: {
                            label: 'Kapat',
                            class: 'btn-secondary w-100',
                          }
                        }
                      });
                    }}/>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div className="withdrawable-widget">
            <div>

              <div className={"d-flex align-items-center"}><p className={"mb-0 me-1"}>ÇEKİLEBİLİR TUTAR</p> <img
                  src={infoIcon} alt="-"/></div>
              <p className="fw-bold mb-0">{CurrencyFormat(auth.member.balance_debit_available)} TL</p>
            </div>

            <div>

              <div className={"d-flex align-items-center"}><p className={"mb-0 me-1"}>ÇEKİLEMEZ TUTAR</p> <img
                  src={infoIcon} alt="-"/></div>
              <p className="fw-bold mb-0">
                {CurrencyFormat(Number(auth.member.balance_point))} TL
              </p>
            </div>

            <div>
              <div className={"d-flex align-items-center"}><p className={"mb-0 me-1"}>TOPLAM BAKİYE</p> <img
                  src={infoIcon} alt="-"/></div>
              <p className="fw-bold mb-0">{CurrencyFormat(Number(auth.member.balance_debit) + Number(auth.member.balance_point))} TL</p>
            </div>
          </div>}

        <form className="withdraw-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={"row mb-2"}>
            <div className={`col-lg-3 col-sm-12 align-items-center d-flex ${isMobile ? 'mb-2' : 'justify-content-end'}`}>
            <label className="fw-bold">KAYITLI BANKA HESABI</label>
          </div>
          <div className={`col-lg-6 col-sm-12 ${isMobile ? 'mb-2' : ''}`}>
            <select className="form-control w-100" {...register<any>("account_id", {required: true, min: 1})}>
              <option value={-1}>Kayıtlı Hesabınızı Seçiniz</option>
              {accountList?.map((account: any, index: number) => {
                return (
                    <option key={`account-${account.id}`} value={account.id}>
                      {account.bank_name} - {account.iban}
                    </option>
                );
              })}
            </select>
            {errors.account_id && <span className="text-danger">Lütfen bir hesap seçiniz.</span>}
          </div>
          <div className={`col-lg-3 col-sm-12 ${isMobile ? 'mb-2' : ''}`}>
            <button
                className={`btn btn-outline-success align-items-center d-flex justify-content-center w-100`}
                type="button"
                onClick={() => navigate("/banka-hesaplarim")}
            >
              Hesap Ekle <img src={plusIcon} className={"ms-1"} alt="Add Account" width={18}/>
            </button>
          </div>
        </div>
        <div className={"row mb-2"}>
          <div className={`col-lg-3 col-sm-12 align-items-center d-flex ${isMobile ? 'mb-2' : 'justify-content-end'}`}>
            <label className="fw-bold">TUTAR</label>
          </div>
          <div className={`col-lg-6 col-sm-12 ${isMobile ? 'mb-2' : ''}`}>
            <input
                type="number"
                pattern="\d*"
                disabled={auth.member?.balance_debit_available == 0}
                className="form-control"
                max={auth.member?.balance_debit_available || 1000000}
                placeholder={"0.00"}
                onKeyDown={preventNegativeValues}
                {...register<any>("amount", {required: true, min: 10})}
                maxLength={10}
                min={10}
                step={0.01}
            />
            {errors.amount && <span className="text-danger">Lütfen geçerli bir miktar giriniz.</span>}
          </div>
        </div>
        <div className={"row mb-2"}>
          <div className={"col-12 d-flex justify-content-center"}>
            <input id="sure" type="checkbox" {...register("sure", {required: true})} />
            <label htmlFor="sure" className="checkbox-text fw-medium ">
              BANKA BİLGİLERİMİN DOĞRU OLDUĞUNA EMİNİM
            </label>
            {errors.sure && <p className="invalid-feedback">Kutucuğu işaretlemelisiniz.</p>}
          </div>
        </div>
        <div className="important-notes mt-5">
          <h6>Dikkat Edilmesi Gereken Önemli Hususlar:</h6>
          <ul>
            <li>Sadece kendinize ait olan vadesiz bir banka hesabına para çekme talebinde bulunabilirsiniz.</li>
            <li>Minimum para çekim limiti en az 10 TL, en fazla 100.000 TL'dir.</li>
            <li> Atm kartsız işlem menüsü üzerinden 4nala.com hesaplarına transfer edilen tutarlar sadece at yarışı bahislerine katılmak için kullanılabilir olup banka hesabınıza gönderimi yapılamamaktadır.</li>
            <li>
              İlk para çekme talebiniz onaylanmadan ikinci bir para çekme talebiniz kabul edilemez.
            </li>
            <li>Anlaşmalı bankalarda 7 gün 24 saat, anlaşmalı olmayan bankalarda mesai saatleri içinde 100.000 TL'ye kadar anında para transferi yapılabilir, 100.000 TL ve üzeri tutarlardaki işlemler takip eden mesai saatleri içerisinde banka hesabınıza aktarılır</li>
          </ul>
        </div>

        <button id={"id_submit_withdraw"} className="btn btn-warning w-100 ">
          &nbsp;Çekim Emri Ver&nbsp;
        </button>
        {/* <div className="form-row align-items-baseline gap-2">
          <label className="col-lg-4 col-sm-12 fw-bold w-25 text-end">KAYITLI BANKA HESABI</label>
          <div className="d-flex flex-column w-50 col-lg-4 col-sm-12">
            <select className="form-control" {...register<any>("account_id", { required: true, min: 1 })}>
              <option value={-1}>Kayıtlı Hesabınızı Seçiniz</option>
              {accountList?.map((account: any, index: number) => {
                return (
                  <option key={`account-${account.id}`} value={account.id}>
                    {account.bank_name} - {account.iban}
                  </option>
                );
              })}
            </select>
            {errors.account_id && <span className="text-danger">Lütfen bir hesap seçiniz.</span>}
          </div>

          <button
            className={`btn btn-outline-success align-items-center gap-2 col-lg-4 col-sm-12 ${
              accountList && accountList.length === 0 ? "d-flex" : "d-flex"
            }`}
            type="button"
            onClick={() => navigate("/banka-hesaplarim")}
          >
            Hesap Ekle <img src={plusIcon} alt="Add Account" width={18} />
          </button>
        </div>

        <div className="form-row align-items-baseline gap-2">
          <label className="fw-bold w-25 text-end">TUTAR</label>
          <div className="d-flex flex-column w-50">
            <input
              type="number"
              pattern="\d*"
              disabled={auth.member?.balance_debit_available == 0}
              className="form-control"
              max={auth.member?.balance_debit_available || 1000000}
              placeholder={"0.00"}
              {...register<any>("amount", { required: true, min: 0.1 })}
              maxLength={10}
              min={0}
              step={0.01}
            />
            {errors.amount && <span className="text-danger">Lütfen geçerli bir miktar giriniz.</span>}
          </div>
        </div>

        <div className="form-row sure-box flex-column ">
          <div className="checkbox w-50">
            <input id="sure" type="checkbox" {...register("sure", { required: true })} />
            <label htmlFor="sure" className="checkbox-text fw-medium ">
              BANKA BİLGİLERİMİN DOĞRU OLDUĞUNA EMİNİM
            </label>
            {errors.sure && <p className="invalid-feedback">Kutucuğu işaretlemelisiniz.</p>}
          </div>
        </div>

        <div className="important-notes mt-5">
          <h6>Dikkat Edilmesi Gereken Önemli Hususlar:</h6>
          <ul>
            <li>Sadece kendinize ait olan vadesiz bir banka hesabına para çekme talebinde bulunabilirsiniz!</li>
            <li>Para çekme talebinde bulunmadan önce lütfen 4nala bakiyenizi kontrol ediniz.</li>
            <li>4nala bakiyenizi aşan tutarlarda ödeme yapılamamaktadır.</li>
            <li>İlk para çekme talebiniz onaylanmadan ikinci bir para çekme talebiniz kabul edilemez.</li>
            <li>
              Kredi kartınıza para gönderimi yapılamamaktadır. Banka hesap kartı ve banka müşteri numaraları
              tanımlandığında da para gönderimi yapılamamaktadır.
            </li>
            <li>Sadece TL olarak ödeme yapılabilmektedir.</li>
          </ul>
        </div>

        <button id={"id_submit_withdraw"} className="btn btn-warning w-100 ">
          &nbsp;Çekim Emri Ver&nbsp;
        </button>*/}
      </form>
    </div>
      </div>
    </>
  );
}
