import "./member.css";
import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {env, resultsUrlPrefix} from "../../constants/global.vars";
import {CurrencyFormat, dateTimeFormat, mobileView, useForceUpdate} from "../../services/misc.functions";
import {
    agfCondBets, availableBettingSubTypes,
    BettingTypesMap,
    BulletinMapCardID,
    DailyRaces,
    HippodromesMap,
    OfficialResults
} from "../../store/bulletin.store";
import {
    betSlipActionSubject,
    tjkBetSlipOptions,
    tjkBetslipStore,
    tjkOrderedBetTypes,
    tjkSingleLegBetTypes,
    tjkSortedBetTypes, updateBetCount
} from "../../services/betslip.service";
import _ from "lodash";
import {modalServiceSubject} from "../../services/modal.service";
import {GetStablemateIcon} from "../betting/components/betting-datatable";
import {isMobile} from "../../App";
import { useNavigate} from "react-router-dom";
import clockIcon from "../../assets/feather/clock.svg";
import {bulletinProvider} from "../../services/bulletin.service";
import paths from "../../paths";
import iconInfo from "../../assets/feather/info.svg";
import chevronDown from "../../assets/feather/chevron-down.svg";
import chevronUp from "../../assets/feather/chevron-up.svg";
import {auth} from "../../store/auth.store";

const tabs = [
    {value: "bekleyen-biletlerim", label: "BEKLEYEN", content: <Tickets status={null}/>},
    {value: "kazanan-biletlerim", label: "KAZANAN", content: <Tickets status={2}/>},
    {value: "kaybeden-biletlerim", label: "KAYBEDEN", content: <Tickets status={3}/>},
    {value: "kayitli-biletlerim", label: "KAYITLI", content: <Tickets status={6}/>},
    {value: "hazir-kuponlar", label: "HAZIR KUPONLAR", content: <Tickets status={7}/>},
];

const statusMap: any = {
    0: {label: "Beklemede", class: "text-bg-secondary"},
    1: {label: "Oynandı", class: "text-bg-secondary"},
    2: {label: "Kazandı", class: "text-bg-success"},
    3: {label: "Kaybetti", class: "text-bg-danger"},
    4: {label: "İptal", class: "text-bg-secondary"},
    5: {label: "Oynanamadı / Hatalı", class: "text-bg-danger"},
    6: {label: "Kayıtlı", class: "text-bg-secondary"},
    7: {label: "Hazır Kupon", class: "text-bg-secondary"},
}

export function MemberTickets() {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const forceUpdate = useForceUpdate();
    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    useEffect(() => {
        if (window.location.pathname.includes('kayitli')) {
            setSelectedTab(tabs[3]);
            forceUpdate();
        }
    }, [window.location.pathname]);

    return (
        <>
            <h1>Biletlerim</h1>
            <div className="page-widget">
                <svg style={{display: "none"}}>
                    <defs>
                        <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                    </defs>
                </svg>

                {/* Tab Header */}
                <div className={"tab-scroll"}>
                    <ul className="nav nav-tabs">
                        {tabs.map(({value, label}: { value: string; label: string }, index) => {
                            if (value === 'hazir-kuponlar') {
                                if (auth.member.is_editor === true) {
                                    return (
                                        <li
                                            key={`tabs-index-${index}`}
                                            className={`nav-item ${selectedTab.value === value ? "active" : ""}`}
                                            onClick={handleSelect}
                                        >
                                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                                <use xlinkHref="#tabshape"></use>
                                            </svg>
                                            <a className="nav-link" id={`${index}`}>
                                                {label.toUpperCase()}
                                            </a>
                                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                                <use xlinkHref="#tabshape"></use>
                                            </svg>
                                        </li>
                                    );
                                }
                            } else {
                                return (
                                    <li
                                        key={`tabs-index-${index}`}
                                        className={`nav-item ${selectedTab.value === value ? "active" : ""}`}
                                        onClick={handleSelect}
                                    >
                                        <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                            <use xlinkHref="#tabshape"></use>
                                        </svg>
                                        <a className="nav-link" id={`${index}`}>
                                            {label.toUpperCase()}
                                        </a>
                                        <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                            <use xlinkHref="#tabshape"></use>
                                        </svg>
                                    </li>
                                );
                            }

                        })}
                    </ul>
                </div>
                <div className="p-1 p-lg-3">{selectedTab.content}</div>
            </div>
        </>
    )
}


let RaceMapping: any = {};
let PlayableMapping: any = {};
let ScoreMapping: any = {};
let horseSeparator = ','
let runSeparator = '/'
let pageShown = 1;
let pageMaxSize = 1;


function Tickets(prop: any) {

    const status = prop.status;
    const [listTickets, setListTickets] = useState<any>(null);
    const [listDetails, setListDetails] = useState<any>([]);
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();


    const loadData = (page: any) => {
        pageShown = page;
        let api = new ApiService();
        api.start('post', env.accounting_api + '/games/api/tjk/coupons', {
            status: status,
            page: pageShown
        }, true).then((res: any) => {
            if (res.status) {

                pageMaxSize = res.data.total;

                if (!listTickets || pageShown === 1) {
                    setListTickets(res.data.data)
                } else {
                    setListTickets([...listTickets.concat(res.data.data)])
                }

            } else {
                setListTickets(null)
            }
        }).catch(() => {
            setListTickets(null)
        })
    }


    useEffect(() => {
        // status değişince ekranı önce temizliyoruz
        setListTickets(null);
        loadData(1);

    }, [prop, prop.status]);

    if (listTickets === null) {
        return (
            <>
                <p className="text-center p-5">Sayfa yükleniyor lütfen bekleyiniz...</p>
            </>
        );
    } else if (listTickets && listTickets.length === 0) {
        return (
            <>
                <p className="text-center p-5">Listelenecek hiç biletiniz yok.</p>
            </>
        );
    }


    const getOfficeResult = async (ticket: any) => {
        let date: any = ticket.card.split('-')[0].substring(4, 8) + '-' + ticket.card.split('-')[0].substring(2, 4) + '-' + ticket.card.split('-')[0].substring(0, 2);
        if (!OfficialResults[date]) {
            let apiUrl = resultsUrlPrefix + `/${date.split('-')[2]}${date.split('-')[1]}${date.split('-')[0]}/`;
            let api = new ApiService();
            return await api.start('get', apiUrl, null, false).then(
                (result: any) => {
                    console.log(result)
                    if (result.status) {
                        OfficialResults[date] = result.data;
                    }
                }
            ).finally(() => {
                return null;
            })
        }
        return null;
    }


    const getStableMate = (horses: any, horse: any) => {
        if (horse.stablemate && Number(horse.stablemate) > 0) {
            return horses.filter((e: any) => Number(e.stablemate) === Number(horse.stablemate))
        }
        return [];
    }

    const addToRaceMap = async (ticket: any) => {

        console.log(ticket)

        return await getOfficeResult(ticket).then(() => {
            let date: any = ticket.card.split('-')[0].substring(4, 8) + '-' + ticket.card.split('-')[0].substring(2, 4) + '-' + ticket.card.split('-')[0].substring(0, 2);
            console.log("OfficialResults", OfficialResults[date])

            let hippodrome = DailyRaces.find((h: any) => {
                console.log(h.card_id);
                console.log(ticket.card_id);
                return Number(h.card_id) === Number(ticket.card_id);
            })
            console.log(hippodrome);
            if (hippodrome && hippodrome.runs) {

                for (let rRun of hippodrome.runs) {
                    rRun.is_finished = false;
                    console.log(rRun);
                    PlayableMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                    RaceMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                }
            }

            hippodrome = OfficialResults[date].find((el: any) => (el.id.split('-')[1]) === ticket.card.split('-')[1]);
            if (hippodrome && hippodrome.runs) {
                for (let rRun of hippodrome.runs) {
                    rRun.is_finished = true;
                    RaceMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                }
            }

            if (tjkOrderedBetTypes.includes(Number(ticket.bet_type)) || [52, 55, 62].includes(Number(ticket.bet_type))) {


                let winners = [];

                let run = RaceMapping[`${ticket.card_id}-${ticket.race_id}`]
                if (run !== undefined) {
                    let horses = _.orderBy(run.horses.filter((el: any) => el.result !== '' && el.result !== null), [(e) => Number(e.result)])
                    for (let i in Array.from(Array(BettingTypesMap[Number(ticket.bet_type)].column).keys())) {

                        console.log(i)

                        if (run && run.is_finished !== false) {
                            winners.push(horses[i].no)
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = winners.join(runSeparator)
                        } else {
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = '-'
                        }
                    }
                }
            }

            if (tjkSortedBetTypes.includes(Number(ticket.bet_type)) || tjkSingleLegBetTypes.includes(Number(ticket.bet_type))) {

                console.log("tjkSingleLegBetTypes", BettingTypesMap[Number(ticket.bet_type)].horseCount)

                let run = RaceMapping[`${ticket.card_id}-${ticket.race_id}`]
                let horses = _.orderBy(run.horses.filter((el: any) => el.result !== ''), [(e) => Number(e.result)])
                let winners = [];
                for (let i in Array.from(Array(BettingTypesMap[Number(ticket.bet_type)].horseCount).keys())) {
                    winners.push(horses[i].no)
                }
                ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = winners.join(runSeparator)
            }


            if (![52, 55, 62].includes(Number(ticket.bet_type)) && !tjkOrderedBetTypes.includes(Number(ticket.bet_type)) && !tjkSortedBetTypes.includes(Number(ticket.bet_type)) && !tjkSingleLegBetTypes.includes(Number(ticket.bet_type))) {
                for (let i in Array.from(Array(ticket.legs.length).keys())) {

                    console.log(`${ticket.card_id}-${ticket.race_id + Number(i)}`)

                    let run = RaceMapping[`${ticket.card_id}-${ticket.race_id + Number(i)}`]
                    if (run !== undefined) {
                        let horses = _.orderBy(run.horses, (e) => Number(e.result === '' || e.result === null ? 99999 : e.result))

                        if (run && run.is_finished) {

                            let winners = [];
                            winners.push(horses[0].no)

                            let stablemate = getStableMate(horses, horses[0])
                            if (stablemate.length > 0) {
                                winners.push(...stablemate.map((e: any) => e.no))
                            }

                            if (String(horses[0].result) === "1" && String(horses[1].result) === "1") {
                                winners.push(horses[1].no)
                                let stablemate = getStableMate(horses, horses[1])
                                if (stablemate.length > 0) {
                                    winners.push(...stablemate.map((e: any) => e.no))
                                }
                            }


                            if (agfCondBets.includes(ticket.bet_type)) {

                                let AGF: any = 0
                                //fixme AGF konusu net değil bi tekrar yazmak lazım
                                if (horses[0].AGF_rank1 && Number(horses[0].AGF_rank1) === 1) {
                                    AGF = 1
                                }
                                if (horses[0].EFG) {
                                    AGF = 1
                                }

                                if (AGF === 1) {

                                    let not_run_horses = horses.filter((e: any) => e.degree === null && (e.result === null || e.result === ''))
                                    for (let noRun of not_run_horses) {
                                        winners.push(noRun.no)
                                        let stablemate = getStableMate(horses, noRun)
                                        if (stablemate.length > 0) {
                                            winners.push(...stablemate.map((e: any) => e.no))
                                        }
                                    }

                                }

                                if (Number(ticket.bet_type) === 19) {
                                    winners.push(horses[1].no)
                                }


                            } else if ([47, 48].includes(ticket.bet_type)) {
                                winners.push(horses[1].no)
                                if (horses[2]) {
                                    winners.push(horses[2].no)
                                }
                            } else if ([2, 4].includes(ticket.bet_type)) {
                                winners.push(horses[1].no)
                            }


                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + Number(i)}`] = [...Array.from(new Set(winners))].join(horseSeparator)

                        } else {
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + Number(i)}`] = '-'
                        }
                    }


                }
            }
        });
    }

    const cancelTjkCoupon = (c: any) => {
        modalServiceSubject.next({
            title: 'Emin misiniz?',
            content: `${c.approve_code || c.id} numaralı kuponunuzu iptal etmek istediğinizden emin misiniz?`,
            confirm: {
                sure: {
                    class: 'btn-danger',
                    label: 'İptal et',
                    action: () => {

                        let api = new ApiService();
                        api.start('post', env.accounting_api + '/games/api/tjk/cancel', {
                            coupon_id: c.id
                        }, true)
                            .then((res: any) => {
                                if (res.status) {

                                    let i = listTickets.indexOf(c);
                                    listTickets.splice(i, 1)
                                    setListTickets([...listTickets]);

                                } else {

                                    let modalTitle = "İşlem başarısız!";
                                    if (res && res.data && res.data.error && res.data.error.error_description) {
                                        modalTitle = res.message;
                                        res.message += `<p>${res.data.error.error_description}</p>`
                                    }

                                    modalServiceSubject.next({
                                        title: modalTitle,
                                        content: res.message,
                                        timeout: 10000,
                                        confirm: {
                                            cancel: {
                                                label: 'Tamam'
                                            }
                                        }
                                    })
                                }
                            })
                            .catch((err: any) => {
                                console.log(err)
                                modalServiceSubject.next({
                                    title: 'Hata',
                                    content: err,
                                    timeout: 3000,
                                    confirm: {
                                        cancel: {
                                            label: 'Tamam'
                                        }
                                    }
                                })
                            })

                    },
                },
                cancel: {
                    label: 'Vazgeç',
                }
            }
        })
    }

    const deleteTjkCoupon = (c: any) => {
        modalServiceSubject.next({
            title: 'Emin misiniz?',
            content: `${c.approve_code || c.id} numaralı kuponunuzu silmek istediğinizden emin misiniz?`,
            confirm: {
                sure: {
                    class: 'btn-danger',
                    label: 'Sil',
                    action: () => {

                        let api = new ApiService();
                        api.start('post', env.accounting_api + '/games/api/tjk/delete-saved', {
                            coupon_id: c.id
                        }, true)
                            .then((res: any) => {
                                if (res.status) {

                                    let i = listTickets.indexOf(c);
                                    listTickets.splice(i, 1)
                                    setListTickets([...listTickets]);

                                } else {

                                    let modalTitle = "İşlem başarısız!";
                                    if (res && res.data && res.data.error && res.data.error.error_description) {
                                        modalTitle = res.message;
                                        res.message += `<p>${res.data.error.error_description}</p>`
                                    }

                                    modalServiceSubject.next({
                                        title: modalTitle,
                                        content: res.message,
                                        timeout: 10000,
                                        confirm: {
                                            cancel: {
                                                label: 'Tamam'
                                            }
                                        }
                                    })
                                }
                            })
                            .catch((err: any) => {
                                console.log(err)
                                modalServiceSubject.next({
                                    title: 'Hata',
                                    content: err,
                                    timeout: 3000,
                                    confirm: {
                                        cancel: {
                                            label: 'Tamam'
                                        }
                                    }
                                })
                            })

                    },
                },
                cancel: {
                    label: 'Vazgeç',
                }
            }
        })
    }


    const playTicket = (ticket: any) => {


        let betType = BulletinMapCardID[Number(ticket.card_id)].wager_details.find((w: any) => Number(w.type) === Number(ticket.bet_type))
        console.log("betType", betType)
        if (betType && betType.runs.includes(ticket.race_id)) {

            let slipItem = tjkBetslipStore.filter((el: any) => {
                if (
                    el.hippodrome === Number(BulletinMapCardID[Number(ticket.card_id)].id) &&
                    el.bettingType === Number(ticket.bet_type) &&
                    el.bettingSubType === Number(ticket.race_id)
                ) {
                    return el;
                }
            })
            for (let item of slipItem) {
                tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
            }
            ticket.legs.map((run: any, legIndex: number) => {
                run.map((horse: any, horseIndex: number) => {

                    let isExist: any = tjkBetslipStore.find((el: any) => el.hippodrome === Number(BulletinMapCardID[Number(ticket.card_id)].id)
                        && el.bettingType === Number(ticket.bet_type)
                        && el.bettingSubType === ticket.race_id
                        && el.column === legIndex + 1
                        && el.run === Number(ticket.race_id + legIndex)
                        && el.horse === String(horse));
                    if (!isExist) {
                        let runNo = ticket.race_id;
                        if (BettingTypesMap[Number(ticket.bet_type)].columnLabel !== 'At') {
                            runNo = runNo + legIndex;
                        }

                        if (BulletinMapCardID[Number(ticket.card_id)].runsMap[Number(runNo)].horseMap[horse].run_status) {
                            tjkBetslipStore.push({
                                hippodrome: Number(BulletinMapCardID[Number(ticket.card_id)].id),
                                bettingType: Number(ticket.bet_type),
                                bettingSubType: ticket.race_id,
                                column: legIndex + 1,
                                run: Number(runNo),
                                horse: String(horse)
                            });
                        }
                    }

                })
            })
            console.log(tjkBetslipStore);
            tjkBetSlipOptions.hippodrome = BulletinMapCardID[Number(ticket.card_id)];
            bulletinProvider.getAvailableBettingTypes(BulletinMapCardID[Number(ticket.card_id)]);
            bulletinProvider.selectBettingType(BulletinMapCardID[Number(ticket.card_id)], Number(ticket.bet_type));
            bulletinProvider.selectBettingSubType(availableBettingSubTypes.find((el: any) => Number(el.id) === Number(ticket.race_id)));
            tjkBetSlipOptions.column = 1;
            tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
            updateBetCount.status = true;
            betSlipActionSubject.next({action: "clear", data: null})
            navigate(`${paths.betting.url}/${BulletinMapCardID[Number(ticket.card_id)].keyword.toLowerCase()}${BulletinMapCardID[Number(ticket.card_id)]._id}`);
        } else {
            modalServiceSubject.next({
                title: 'Kapanmış Bahis',
                content: '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir ya da yorumcularımızın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }
    }


    const showDetail = (ticket: any) => {

        let i: any = listDetails.findIndex((e: any) => e === ticket.id)

        if (i === -1) {

            addToRaceMap(ticket).then(() => {
                listDetails.push(ticket.id)
                setListDetails(listDetails);
                forceUpdate();
            })

        } else {
            listDetails.splice(i, 1)
            setListDetails(listDetails);
            forceUpdate();
        }

        console.log(RaceMapping[`${ticket.card_id}-${ticket.race_id}`]);

    }


    console.log("ScoreMapping", ScoreMapping)


    return (
        <div className={"table-responsive"}>
            <table className="table table-borderless betting-table ticket-table">
                <thead>
                <tr>
                    {!isMobile && <th>Bilet No</th>}
                    <th>Tarih</th>
                    {!isMobile && <th>Hipodrom</th>}
                    <th>Bahis Türü</th>
                    {!isMobile && <th>Koşu</th>}
                    {status === 2 && <th>Kazanç</th>}
                    {!isMobile && <th>Tutar</th>}
                    {!isMobile && <th>#</th>}
                    {isMobile && <th className={"text-end"}>Tutar</th>}
                </tr>
                </thead>
                <tbody>
                {listTickets &&
                    listTickets.map((ticket: any, keyTicket: number) => {
                        return (
                            <React.Fragment key={`tickets-${keyTicket}`}>
                                <tr>
                                    <th className="seperator"></th>
                                </tr>
                                <tr className={`${listDetails.includes(ticket.id) ? 'active' : ''} cursor-pointer ticket`}
                                    onClick={(e: any) => {
                                        if (e.target.nodeName === 'TD' || e.target.nodeName === 'IMG' ) {
                                            showDetail(ticket)
                                        }
                                    }}>
                                    {!isMobile &&
                                        <td>{ticket.status !== 6 && ticket.approve_code ? ticket.approve_code : (ticket.status === 6 ? ticket.id : "Oynanıyor")}</td>}
                                    <td onClick={() => {

                                            showDetail(ticket)

                                    }}>
                                        {isMobile && <span>{ticket.card ? HippodromesMap[ticket.card.split("-")[1]]?.YER : ''}<br/></span>}
                                        <span className={"text-center"}
                                              dangerouslySetInnerHTML={{__html: ticket.status !== 6 && ticket.approve_code ? dateTimeFormat(ticket.approve_date).replaceAll(' ', '<br/>') : dateTimeFormat(ticket.create_date).replaceAll(' ', '<br/>')}}></span>
                                    </td>
                                    {!isMobile && <td>{ticket.card ? HippodromesMap[ticket.card.split("-")[1]]?.YER : ''}</td>}
                                    <td className={"nowrap-ellipsis tooltip"}>
                                        {BettingTypesMap[Number(ticket.bet_type)]?.shortName != null ? BettingTypesMap[Number(ticket.bet_type)]?.shortName : BettingTypesMap[Number(ticket.bet_type)]?.name} {[52, 55, 62].includes(Number(ticket.bet_type)) && ticket.legs.length === 1 ? '(V)' : ''}
                                        {isMobile && <span><br/>({ticket.race_id})</span>}
                                        {!listDetails.includes(ticket.id) && <span
                                            className={"tooltip-text"}>{BettingTypesMap[Number(ticket.bet_type)]?.name} {[52, 55, 62].includes(Number(ticket.bet_type)) && ticket.legs.length === 1 ? '(Virgüllü)' : ''}</span>}
                                    </td>
                                    {!isMobile && <td>{ticket.race_id}</td>}
                                    {ticket.status === 2 && <td className="text-success fw-bold">
                                        {ticket.refund !== ticket.winning && ticket.winning + '₺'}
                                        {ticket.refund && Number(ticket.refund) > 0 ?
                                            <span
                                                className="d-block text-dark fw-normal">( {ticket.refund}₺ iade)</span> : <></>}
                                    </td>}
                                    {!isMobile && <td>{CurrencyFormat(Number(ticket.amount || 0))} ₺</td>}
                                    <td className={`${isMobile ? 'text-end' : ''}`}>
                                        <div className={"d-flex justify-content-between align-items-center"}>
                                            {isMobile && <> {CurrencyFormat(Number(ticket.amount || 0))} ₺ <br/></>}
                                            {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status === 0 && ticket.status === 1 ?
                                                <span className="badge text-bg-danger cursor-pointer" onClick={() => {
                                                    cancelTjkCoupon(ticket)
                                                }}>
                                            İptal Et
                                          </span> : <span className={"badge " + statusMap[ticket.status].class}>
                                            {statusMap[ticket.status].label}
                                          </span>}
                                            {
                                                listDetails.includes(ticket.id) ? <img src={chevronUp}/> : <img  src={chevronDown}/>
                                            }
                                        </div>

                                    </td>
                                </tr>

                                {listDetails.includes(ticket.id) ? <>

                                    <tr className="ticket-detail new">
                                        <th colSpan={isMobile ? 4 : ticket.status === 2 ? 8 : 7} className="pt-0">
                                            {isMobile &&  <h3>Bilet Detayı <span className="float-end">
                                            NO: {ticket.status !== 6 && ticket.approve_code ? ticket.approve_code : (ticket.status === 6 ? ticket.id : "Oynanıyor")}
                                          </span></h3>}
                                            <div className={"ticket-detail-container"}>
                                                <div className={"ticket-detail-inner-content"}>
                                                    <table className="table table-striped coupon-detail">
                                                        <tbody>
                                                        <tr>
                                                            <td className="text-left" style={{width: 100}}>Hipodrom</td>
                                                            <td className="text-left fw-normal">{ticket.card ? HippodromesMap[ticket.card.split("-")[1]]?.HIPODROM : ''}</td>
                                                            <td className="text-left" style={{width: 100}}>Bahis Türü
                                                            </td>
                                                            <td className="text-left fw-normal">{BettingTypesMap[Number(ticket.bet_type)]?.shortName != null ? BettingTypesMap[Number(ticket.bet_type)]?.shortName : BettingTypesMap[Number(ticket.bet_type)]?.name}</td>
                                                            {!isMobile &&
                                                                <td className="text-left"
                                                                    style={{width: 100}}>Koşu</td>}
                                                            {!isMobile &&
                                                                <td className="text-left fw-normal">{ticket.race_id}</td>}
                                                        </tr>
                                                        {
                                                            isMobile && <tr>
                                                                <td className="text-left" style={{width: 100}}>Koşu</td>
                                                                <td className="text-left fw-normal">{ticket.race_id}</td>
                                                                <td className="text-left">Tutar</td>
                                                                <td
                                                                    className="text-left fw-normal">{CurrencyFormat(Number(ticket.amount || 0))}₺
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="text-left">Bahis Sayısı</td>
                                                            <td className="text-left fw-normal">{ticket.bet_count}</td>
                                                            <td className="text-left">Misli</td>
                                                            <td className="text-left fw-normal">{ticket.multiply}</td>
                                                            {!isMobile && <td className="text-left">Tutar</td>}
                                                            {!isMobile && <td
                                                                className="text-left fw-normal">{CurrencyFormat(Number(ticket.amount || 0))}₺</td>}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">Sonuçlar</td>
                                                            <td className="text-left fw-normal">
                                                                <div
                                                                    className={"d-flex justify-content-between align-items-center"}>
                                                                    <div>{BettingTypesMap[Number(ticket.bet_type)].columnLabel === 'At' && ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`]}

                                                                        {BettingTypesMap[Number(ticket.bet_type)].columnLabel !== 'At' && <>{[...Array.from(Array(ticket.legs.length).keys()).map((e: any) => {
                                                                            return ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + e}`]
                                                                        })].join(runSeparator)}</>}
                                                                    </div>
                                                                    <div>
                                                                        <img src={iconInfo} alt="i"
                                                                             onClick={() => {
                                                                                 modalServiceSubject.next({
                                                                                     case: 'info',
                                                                                     title: 'Önemli Uyarı!',
                                                                                     content: `<p class="text-center"> Kazançlı at renklendirmesinde sizlere daha hızlı bilgi
                                                                                bilgilendirme amaçlıdır.
                                                                                Bu sayfadaki renklendirme ile Merkezi Bahis Sistemi kayıtları arasında
                                                                                bir tutarsızlık olması
                                                                                durumunda, renklendirme bilet kazanç durumunda etki etmez ve Merkezi
                                                                                Bahis Sistemi kayıtları
                                                                                esas alınır.</p>`,
                                                                                     confirm: {
                                                                                         cancel: {
                                                                                             label: 'Kapat',

                                                                                         }
                                                                                     }
                                                                                 })
                                                                             }}
                                                                             className={'ms-2'}
                                                                             width={21} height={21}/>
                                                                    </div>
                                                                </div>


                                                            </td>
                                                            <td className="text-left">Durum</td>
                                                            <td className="text-left fw-normal"><span
                                                                className={"badge " + statusMap[ticket.status]?.class}>{statusMap[ticket.status]?.label}</span>
                                                            </td>
                                                            {!isMobile && <td className="text-left">Kazanç</td>}
                                                            {!isMobile && <td className="text-left fw-normal">
                                                                {ticket.refund !== ticket.winning && ticket.winning + '₺'}
                                                                {ticket.refund && Number(ticket.refund) > 0 ?
                                                                    <span
                                                                        className="d-block text-dark fw-normal">( {ticket.refund}₺ iade)</span> : <></>}
                                                            </td>}
                                                        </tr>
                                                        {isMobile && status === 2 && <tr>
                                                            <td className="text-left" colSpan={2}>Kazanç</td>
                                                            <td className="text-left fw-normal" colSpan={2}>
                                                                {ticket.refund !== ticket.winning && ticket.winning + '₺'}
                                                                {ticket.refund && Number(ticket.refund) > 0 ?
                                                                    <span
                                                                        className="d-block text-dark fw-normal">( {ticket.refund}₺ iade)</span> : <></>}
                                                            </td>
                                                        </tr>}
                                                        </tbody>
                                                    </table>

                                                    <div className={`coupon-legs ${isMobile ? 'row' : "row"}`}>
                                                        {ticket.legs.map((leg: any, key: number) => {
                                                            return (
                                                                <div key={"tickets-" + key}
                                                                     className={`${isMobile ? 'col-12' : ticket.legs.length > 1 ? 'col-6 mt-2' : 'col-12'}`}
                                                                     style={{width: `${isMobile ? `` : ``}`}}>

                                                                    <div
                                                                        className="run-no d-flex justify-content-between px-3">
                                                                        {BettingTypesMap[Number(ticket.bet_type)].columnLabel === 'Koşu' ? Number(ticket.race_id) + Number(key) : Number(key + 1)}. {BettingTypesMap[Number(ticket.bet_type)].columnLabel}
                                                                        <span
                                                                            className={"d-flex justify-content-center"}> <img
                                                                            alt={"clock"}
                                                                            className={"me-2"} width={16}
                                                                            src={clockIcon}/> {RaceMapping[`${ticket.card_id}-${BettingTypesMap[Number(ticket.bet_type)].columnLabel === 'At' ? ticket.race_id : ticket.race_id + key}`]?.time}</span>
                                                                    </div>
                                                                    {leg.map((item: any, horseKey: number) => {

                                                                        let horse = null;
                                                                        try {
                                                                            horse = RaceMapping[`${ticket.card_id}-${ticket.race_id}`].horses.find((e: any) => Number(e.no) === Number(item))
                                                                            if (BettingTypesMap[Number(ticket.bet_type)].columnLabel !== 'At') {
                                                                                horse = RaceMapping[`${ticket.card_id}-${ticket.race_id + key}`].horses.find((e: any) => Number(e.no) === Number(item))
                                                                            }
                                                                        } catch (e) {
                                                                            return <></>
                                                                        }

                                                                        let scoreString = ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + (BettingTypesMap[Number(ticket.bet_type)].columnLabel === 'At' ? 0 : key)}`];

                                                                        if (tjkSingleLegBetTypes.includes(Number(ticket.bet_type)) || ([52, 55, 62].includes(Number(ticket.bet_type)) && ticket.legs.length === 1)) {
                                                                            scoreString = scoreString.toString().replaceAll('/', ',')
                                                                        }

                                                                        let colScore: any = '';
                                                                        if (BettingTypesMap[Number(ticket.bet_type)].columnLabel === 'At') {
                                                                            colScore = scoreString?.split(runSeparator)[key]
                                                                        } else {
                                                                            colScore = scoreString
                                                                        }


                                                                        return (
                                                                            <div key={`order-detail-horse-${horseKey}`}
                                                                                 className="horse-no d-flex justify-content-between"
                                                                                 style={mobileView ? {background: 'var(--color-bg-white)'} : {}}>
                                                                                <div
                                                                                    className={"text-left " + (horse && scoreString && colScore?.split(horseSeparator)?.includes(horse.no) ? ' text-danger' : '')}
                                                                                    style={{paddingLeft: 5}}>
                                                                                    {horse ? <>{horse.no} - {horse.name}

                                                                                        {RaceMapping[`${ticket.card_id}-${ticket.race_id}`].is_finished ? <>
                                                                                            {!horse.result && horse.degree === null ? '(K)' : (horse.result ? `(${horse.result})` : '')}


                                                                                        </> : <>{horse.run_status === false ? '(K)' : ''}</>}


                                                                                        <GetStablemateIcon
                                                                                            horse={horse}/>


                                                                                    </> : <>{Number(item) === 32 ? (mobileView ? '(S)' : 'HAZIR BAHİS') : item}</>}
                                                                                </div>
                                                                                <div style={{paddingRight: 5}}>
                                        <span>
                                            {horse?.AGF1 ? `AGF: ${horse.AGF1}` : null}
                                            {horse?.AGF2 ? <> {`${horse.AGF1 != null ? '/' : 'AGF:'}`} {`${horse.AGF2}`}</> : null}
                                          </span>
                                                                                </div>
                                                                            </div>)
                                                                    })}

                                                                </div>)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"row"}>
                                                {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status === 0 && ticket.status === 1 &&
                                                    <div className="foot danger two-btn cursor-pointer" onClick={() => {
                                                        cancelTjkCoupon(ticket)
                                                    }}>
                                                        BİLETİ İPTAL ET
                                                    </div>}

                                                {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status === 0 && ticket.status === 1 &&
                                                    <div className="foot float-start  two-btn warning cursor-pointer"
                                                         onClick={() => {
                                                             playTicket(ticket)
                                                         }}>
                                                        TEKRAR OYNA
                                                    </div>}


                                                {ticket.status === 6 && <div
                                                    className={"foot  cursor-pointer " + (RaceMapping[`${ticket.card_id}-${ticket.race_id}`]?.is_finished === false ? 'two-btn danger  float-end ' : '')}
                                                    onClick={() => {
                                                        deleteTjkCoupon(ticket)
                                                    }}>
                                                    KAYDI SİL
                                                </div>}
                                                {ticket.status === 7 && <div
                                                    className={"foot  cursor-pointer danger"}
                                                    onClick={() => {
                                                        deleteTjkCoupon(ticket)
                                                    }}>
                                                    KAYDI SİL
                                                </div>}


                                                {RaceMapping[`${ticket.card_id}-${ticket.race_id}`]?.is_finished === false && ticket.status === 6 &&

                                                    <div className="foot float-start warning cursor-pointer two-btn"
                                                         onClick={() => {
                                                             playTicket(ticket)
                                                         }}>
                                                        OYNA

                                                    </div>}


                                                {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status !== 0 && ticket.status !== 6 && ticket.status !== 7 &&
                                                    <div className="foot cursor-pointer">

                                                    </div>}
                                            </div>
                                        </th>
                                    </tr>
                                </> : <></>}

                            </React.Fragment>
                        );
                    })}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={6} className="ps-0 pt-4">
                        {listTickets && pageMaxSize > listTickets.length &&
                            <button className="btn btn-sm btn-info" onClick={() => {
                                loadData(pageShown + 1)
                            }}>Devamını Yükle</button>}
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
}



