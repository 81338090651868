import {homeHippodrome, homeHippodromeSubject, todayResults, todayResultsSubject} from "../../store/misc.store";
import React, {useEffect} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {availableBettingSubTypes, courseColors} from "../../store/bulletin.store";
import paths from "../../paths";
import {Link} from "react-router-dom";
import {bulletinProvider} from "../../services/bulletin.service";
import {tjkBetSlipOptions} from "../../services/betslip.service";
import {isMobile} from "../../App";
import Countdown from "react-countdown";
import jokeyIcon from "../../assets/img/jokey.png";

export function DailyRacesWidget(props:any) {

    const forceUpdate = useForceUpdate();

    useEffect(() => {

        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        })

        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }

    }, []);

    useEffect(() => {
        let todayResultsSubject_subscription = todayResultsSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            todayResultsSubject_subscription.unsubscribe();
        }
    }, []);


    return (<>

        {homeHippodrome && <>
            {!isMobile && <>
                <div className="float-end mt-2">
                    Nem: <b> %{homeHippodrome?.weather.humidity} </b>
                    Sıcaklık: <b> {homeHippodrome?.weather.temperature}°C </b>
                </div>
                <h1 className="fs-3 ps-0">
                    {homeHippodrome.hippodrome}
                </h1>
            </>}
        </>}

        <table className="table betting-table table-borderless">
            <tbody>
            {homeHippodrome?.runs?.map((run: any, runsIndex: number) => {
                return (<React.Fragment key={`dailyruns-i-${runsIndex}`}>
                    <tr>
                        <th className="seperator"></th>
                    </tr>
                    <tr>
                        <td className="align-content-center fw-semibold">{run.no}. Koşu</td>
                        {!isMobile && <td className="align-content-center">{run.time}</td>}
                        {props.showResults ? <td className={`align-content-center   ${run.status === 0 ?'fst-italic align-items-center' : ''}  ${isMobile ? 'text-end' : ''}`}>

                            {run.status !== 0 ? <>
                                {todayResults[homeHippodrome.keyword] != null && <>
                                    {todayResults[homeHippodrome.keyword].runsMap[run.no] != null && <div className={"d-flex align-items-center"}>
                                        <div>
                                            <img
                                                src={`https://medya-cdn.tjk.org/formaftp/${todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.owner_code}.jpg`}
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = jokeyIcon;
                                                }} width={24}
                                                alt={todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.jokey.short_name}
                                                className="jockey-uniform float-start "/>
                                        </div>
                                        <div>
                                            <span className="d-block fw-bold nowrap-ellipsis">
                                              ({todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.no}) {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.name}
                                            </span>
                                            <span
                                                className={"me-2"}>Derece: {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.degree}</span>
                                            <span
                                                className={"me-2"}>Ganyan: {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.ganyan}</span>
                                            <br/>
                                            <span
                                                className={"me-2 d-flex"}>AGF: {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF1 ?
                                                <span
                                                    className={`${todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF_rank1 === 1 ? 'text-success fw-bold d-block' : 'd-block'}`}>{todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF1} ({todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF_rank1})</span> : null}
                                                {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF2 ?
                                                    <span
                                                        className={`${todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF_rank2 === 1 ? 'text-success fw-bold d-block' : 'd-block'}`}>{todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF2} ({todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.AGF_rank2})</span> : null}</span>

                                        </div>


                                    </div>}
                                </>}
                            </> : <span className="d-block nowrap-ellipsis">Koşu sonuçlanmadı.</span>}
                            {/*<span className={"me-2"}><b>Derece:</b> </span>*/}
                            {/*<span className={"me-2"}><b>Ganyan:</b> </span>*/}
                            {/*<span className={"me-2"}><b>AGF:</b> </span>*/}

                        </td> : <td className={`align-content-center ${isMobile ? 'text-end' : ''}`}>
                            {!isMobile && <span className="course"
                                                style={{backgroundColor: courseColors[run.course]}}> {run.course}</span>}
                            {!isMobile && <>{run.distance}m {isMobile && run.course} {run.group}, {run.type_detail ? <>
                                <span> {run.type_detail}</span></> : null}</>}
                            {isMobile &&
                                <>
                                    {run.status === 0 && homeHippodrome.wager_details[0]?.runs?.includes(Number(run.no)) ?
                                        <>


                                            <Countdown renderer={({hours, minutes, seconds, completed}) => {
                                                if (completed) {
                                                    // Render a completed state
                                                    return <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div style={{width: "33vw"}}>
                                                            <span
                                                                className="d-block fst-italic nowrap-ellipsis">Koşu sonuçlanmadı.</span>
                                                        </div>
                                                        <Link
                                                            style={{width: "30vw"}}
                                                            className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-primary"
                                                            to={`${paths.results.url}/${homeHippodrome?.keyword.toLowerCase()}/${run.no}`}
                                                            onClick={() => {
                                                                window.scrollTo(0, 0)
                                                            }}>
                                                            SONUÇLAR
                                                        </Link></div>;
                                                } else {
                                                    // Render a countdown
                                                    return <div
                                                        className={"d-flex justify-content-between align-items-center"}>
                                                        <div style={{width: "33vw"}}>
                                                            {run.distance}m <span className="course"
                                                                                  style={{backgroundColor: courseColors[run.course]}}> {run.course}</span>
                                                        </div>
                                                        <div className={"d-flex justify-content-between"}
                                                             style={{width: "30vw"}}>
                                                            <div>Kalan:</div>
                                                            <span
                                                                className={"fw-medium fs-6 text-center"}>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span>
                                                        </div>

                                                    </div>;
                                                }
                                            }} date={run.running_date}/>


                                        </> : <div className={"d-flex justify-content-between align-items-center"}>
                                            <div style={{width: "33vw"}} className={"text-start"}>
                                                {run.status !== 0 ? <>
                                                    {todayResults[homeHippodrome.keyword] != null && <>
                                                        {todayResults[homeHippodrome.keyword].runsMap[run.no] != null && <>
                                                            <span className="d-block fw-bold nowrap-ellipsis">
                                                              ({todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.no}) {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.name}
                                                            </span>
                                                            <span>Derece: {todayResults[homeHippodrome.keyword].runsMap[run.no].winnerHorse.degree}</span>
                                                        </>}
                                                    </>}
                                                </> : <span className="d-block nowrap-ellipsis">Koşu sonuçlanmadı.</span>}
                                            </div>

                                            <Link className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-primary"
                                                  style={{width: "30vw"}}
                                                  to={`${paths.results.url}/${homeHippodrome?.keyword.toLowerCase()}/${run.no}`}
                                                  onClick={() => {
                                                      window.scrollTo(0, 0)
                                                  }}>
                                                SONUÇLAR
                                            </Link></div>}</>
                            }
                        </td>}

                        {!isMobile && <td className="text-end" style={{width: '33%'}}>

                            {run.status === 0 && homeHippodrome.wager_details[0]?.runs?.includes(Number(run.no)) ? <>
                                {
                                    props.showResults ?  <Countdown renderer={({hours, minutes, seconds, completed}) => {
                                        if (completed) {
                                            // Render a completed state
                                            return <div

                                                className={"d-flex justify-content-end"}>
                                                <Link
                                                    className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-primary"
                                                    to={`${paths.results.url}/${homeHippodrome?.keyword.toLowerCase()}/${run.no}`}
                                                    onClick={() => {
                                                        window.scrollTo(0, 0)
                                                    }}>
                                                    SONUÇLAR
                                                </Link></div>;
                                        } else {
                                            // Render a countdown
                                            return <div
                                                className={"d-flex justify-content-end me-2"}>
                                                    <div className={"me-2 text-start"} style={{width:'50px'}}>Kalan:</div>
                                                    <span
                                                        style={{width:'70px'}}
                                                        className={"fw-medium fs-6 text-center"}>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span>
                                            </div>;
                                        }
                                    }} date={run.running_date}/> : <>
                                        <Link className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-warning"
                                              to={`${paths.betting.url}/${homeHippodrome?.keyword.toLowerCase()}`}
                                              onClick={() => {

                                                  tjkBetSlipOptions.hippodrome = homeHippodrome;
                                                  bulletinProvider.getAvailableBettingTypes(homeHippodrome);
                                                  bulletinProvider.selectBettingType(homeHippodrome, 1);
                                                  bulletinProvider.selectBettingSubType(availableBettingSubTypes.find((el: any) => Number(el.id) === Number(run.no)));

                                                  tjkBetSlipOptions.column = 1;

                                                  window.scrollTo(0, 0)
                                              }}>
                                            BAHİS YAP
                                        </Link>

                                        <Link className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-outline-primary"
                                              to={`${paths.betting.url}/${homeHippodrome?.keyword.toLowerCase()}/muhtemeller`}
                                              onClick={() => {
                                                  window.scrollTo(0, 0)
                                              }}>
                                            MUHTEMELLER
                                        </Link>

                                        <Link className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-outline-primary"
                                              to={`${paths.betting.url}/${homeHippodrome?.keyword.toLowerCase()}/agf`}
                                              onClick={() => {
                                                  window.scrollTo(0, 0)
                                              }}>
                                            AGF
                                        </Link></>
                                }

                            </> : <>

                                <Link className="btn btn-sm fw-semibold fs-7 rounded-1 me-1 btn-primary"
                                      to={`${paths.results.url}/${homeHippodrome?.keyword.toLowerCase()}/${run.no}`}
                                      onClick={() => {
                                          window.scrollTo(0, 0)
                                      }}>
                                    SONUÇLAR
                                </Link>
                            </>}
                        </td>}
                    </tr>
                </React.Fragment>)
            })}
            </tbody>
        </table>

    </>)
}
