import React, {useEffect, useState} from "react";
import {jockeyStatusUrlPrefix} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {dateFormat} from "../../services/misc.functions";
import iconClock from "../../assets/feather/clock.svg";
import {Storage} from "../../services/localstorege.service";
import {isMobile} from "../../App";
import {BottomSheet} from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import {useNavigate} from "react-router-dom";
import paths from "../../paths";

export function JockeyStatus(props: any) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [data, setData] = useState<any>(false);

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        let cachedData = Storage.get(`reportedJokeys`);
        if (!cachedData) {
            let api = new ApiService()
            api.start('get', jockeyStatusUrlPrefix, null, false)
                .then((res: any) => {
                    if (res.status) {
                        setData(res.data)
                        Storage.set(`reportedJokeys`, res.data, 1 * 60 * 1000);
                    } else {
                        setData([])
                    }
                })
        } else {
            setData(cachedData);
        }
    }


    return (<>

        {data && data.length > 0 && <table className="table table-borderless">
            <thead>
            <tr>
                {!isMobile && props.only !== 'forbidden' && <th>RAPORLU JOKEYLER</th>}
                {!isMobile && props.only !== 'reported' && <th>CEZALI JOKEYLER</th>}
            </tr>
            </thead>
            <tbody>
            <tr>
                {props.only !== 'forbidden' && <td className="p-0 pe-md-2">
                    <table className="w-100 table table-primary table-borderless table-striped mb-1">
                        <thead>
                        <tr>
                            <th>JOKEY ADI</th>
                            <th>BAŞLANGIÇ</th>
                            <th>BİTİŞ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.filter((d: any) => d.type === 'reported').slice(0, props.all ? data.length : 4).map((jokey: any, jIndex: number) => {
                            return (<tr key={`reported-j-${jIndex}`}>
                                <td className="fw-semibold">{isMobile ? jokey.jockey_short_name : jokey.jockey_name}</td>
                                <td>{dateFormat(jokey.from_date)}</td>
                                <td>{dateFormat(jokey.to_date)}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </td>}
                {props.only !== 'reported' && <td className="p-0">
                    <table className="w-100 table table-primary table-borderless table-striped mb-1">
                        <thead>
                        <tr>
                            <th>JOKEY ADI</th>
                            <th>BAŞLANGIÇ</th>
                            <th>BİTİŞ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.filter((d: any) => d.type === 'forbidden').slice(0, 4).map((jokey: any, jIndex: number) => {
                            return (<tr key={`reported-j-${jIndex}`}>
                                <td className="fw-semibold">{isMobile ? jokey.jockey_short_name : jokey.jockey_name}</td>
                                <td>{dateFormat(jokey.from_date)}</td>
                                <td>{dateFormat(jokey.to_date)}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </td>}
            </tr>
            </tbody>
        </table>}


        { props.all !== true && data.length !== 0 && <button className="btn btn-lg btn-warning fw-medium w-100 mt-0"
                 onClick={() => {
                     navigate(`${paths.reportedJokeys.url}`);
                 }}
        >Tüm Jokeyleri Göster</button>}

        <BottomSheet
            open={open}
            expandOnContentDrag={false}
            onDismiss={() => {
                setOpen(false);
            }}
            maxHeight={window.innerHeight - 75}
            header={
                    !isMobile ? <div className={"row"}>
                        <div className={"col-6"}>
                            <h3>RAPORLU JOKEYLER</h3>
                        </div>
                        <div className={"col-6"}>
                            <h3>CEZALI JOKEYLER</h3>
                        </div>
                    </div> : <>
                        {props.only !== 'forbidden' && <h3>RAPORLU JOKEYLER</h3>}
                        {props.only !== 'reported' && <h3>CEZALI JOKEYLER</h3>}
                    </>
               }
        >
            <div className={"py-3 "}>
                {open && <table className="table table-borderless">
                    <thead>
                    <tr>
                        {!isMobile && props.only !== 'forbidden' && <th>RAPORLU JOKEYLER</th>}
                        {!isMobile && props.only !== 'reported' && <th>CEZALI JOKEYLER</th>}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {props.only !== 'forbidden' && <td className="p-0 pe-md-2">
                            <table className="w-100 table table-primary table-borderless table-striped">
                                <thead>
                                <tr>
                                    <th>JOKEY ADI</th>
                                    <th>BAŞLANGIÇ</th>
                                    <th>BİTİŞ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.filter((d: any) => d.type === 'reported').map((jokey: any, jIndex: number) => {
                                    return (<tr key={`reported-j-${jIndex}`}>
                                        <td className="fw-semibold">{isMobile ? jokey.jockey_short_name : jokey.jockey_name}</td>
                                        <td>{dateFormat(jokey.from_date)}</td>
                                        <td>{dateFormat(jokey.to_date)}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </td>}
                        {props.only !== 'reported' && <td className="p-0">
                            <table className="w-100 table table-primary table-borderless table-striped">
                                <thead>
                                <tr>
                                    <th>JOKEY ADI</th>
                                    <th>BAŞLANGIÇ</th>
                                    <th>BİTİŞ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.filter((d: any) => d.type === 'forbidden').map((jokey: any, jIndex: number) => {
                                    return (<tr key={`reported-j-${jIndex}`}>
                                        <td className="fw-semibold">{isMobile ? jokey.jockey_short_name : jokey.jockey_name}</td>
                                        <td>{dateFormat(jokey.from_date)}</td>
                                        <td>{dateFormat(jokey.to_date)}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </td>}
                    </tr>
                    </tbody>
                </table>}
            </div>
        </BottomSheet>


        {!data && <p className="p-4 text-center">

            Yükleniyor lütfen bekleyiniz.</p>}


        {data && data.length === 0 && <p className="p-4 text-center">
            <br/>
            <img alt={"Clock"} src={iconClock} width={64}/> <br/><br/><br/>
            Seçtiğiniz hipdromdaki koşular için raporlu ya da cezalı jokey bilgi bulunmamaktadır.

        </p>}

    </>)
}
