import {dateFormat, useForceUpdate} from "../../../services/misc.functions";
import {Hippodrome} from "../../../models/bulletin.models";
import {bulletinProvider} from "../../../services/bulletin.service";
import {
  betSlipActionSubject,
  clearTjkBetSlip, clearTjkBetSlipOptions,
  mobileBetSlipActions,
  mobileBetSlipSubject,
  tjkBetSlipOptions
} from "../../../services/betslip.service";
import {availableBettingSubTypes, availableHippodromes} from "../../../store/bulletin.store";
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import paths from "../../../paths";
import iconList from "../../../assets/icons/list-tjk.svg";

import {isMobile} from "../../../App";

export function BettingTypeOptions(props:any){

  const params = props.params;
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const hippodrome:Hippodrome = props.hippodrome;
  const availableBettingTypes:any = bulletinProvider.getAvailableBettingTypes(hippodrome);
  const selectBettingType = props.selectBettingType;
  const selectBettingSubType = props.selectBettingSubType;
  useEffect(() => {
    let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res:any) => {
      forceUpdate();
      console.log()
    });
    return () => {
      betSlipActionSubject_subscription.unsubscribe();

    }
  }, []);

  useEffect(() => {
    let mobileBetSlipSubject_subscription = mobileBetSlipSubject.subscribe((res: any) => {
      if (res) {
        forceUpdate();
      }
    })
    return () => {
      mobileBetSlipSubject_subscription.unsubscribe();
    }
  });

  if(mobileBetSlipActions.isOpen){
    return <></>
  }



  return (<div className="bettingtype-options px-4 py-2 p-sm-3 pb-sm-4">
    { isMobile ? <>
          <div className={"row"}>
            <div className={"col-4 pe-0 ps-0"}>
              <label className="hidden-sm">HİPODROMLAR</label>
              <select onChange={(e: any) => {
                clearTjkBetSlipOptions();
                navigate(`${paths.betting.url}/${e.target.value.toLowerCase()}`);
              }} value={tjkBetSlipOptions.hippodrome.keyword + tjkBetSlipOptions.hippodrome._id}>
                {availableHippodromes.map((hippodrome: any, keyHippodrome: number) => {
                  if (hippodrome.wager_details.length > 0 ) {
                    return (<option key={`keyHippodrome-${keyHippodrome}`}
                                    value={hippodrome.keyword+hippodrome._id}>{hippodrome.hippodrome} {new Date(hippodrome.date).getDate() <= new Date().getDate() ? <>{hippodrome.nextRun && ` - ${hippodrome.nextRun.no}. Koşu ${hippodrome.nextRun.time}`}</>: <> - ({dateFormat(hippodrome.date)})</> }</option>)
                  }
                })}
              </select>

            </div>
            {tjkBetSlipOptions.bettingType && <div className="col-4 pe-0 ps-2">
              <label className="hidden-sm">BAHİS TÜRÜ</label>
              <select onChange={(e: any) => {
                selectBettingType(e.target.value);
                tjkBetSlipOptions.complete = false;
                betSlipActionSubject.next(true);
              }} value={tjkBetSlipOptions.bettingType.id}>
                {availableBettingTypes.map((bettingType: any, keyBettingType: number) => {
                  return (
                      <option key={`keyBettingType-${keyBettingType}`} value={bettingType.id}>{bettingType.name}</option>)
                })}
              </select>
            </div>}
            {tjkBetSlipOptions.bettingSubType && <div className="col-4 pe-0 ps-2">
              <label className="hidden-sm">KOŞU</label>
              <select onChange={(e: any) => {
                selectBettingSubType(e.target.value);
              }} value={tjkBetSlipOptions.bettingSubType.id}>
                {availableBettingSubTypes.map((bettingSubType: any, keyBettingSubType: number) => {
                  return (<option key={`keyBettingType-${keyBettingSubType}`}
                                  value={bettingSubType.id}>{bettingSubType.label}</option>)
                })}
              </select>
            </div>}
          </div>
          <div className="row mt-2">
            <div className="col-4  pe-0 ps-0">
              <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}`} onClick={() => {
                // setMobileBetslip(false)
                mobileBetSlipActions.isOpen = false;
                mobileBetSlipSubject.next('update')
              }} className={`btn btn-muhtemeller ${!params.subpage ? 'active' : ''}`}>
                BAHİS YAP
              </Link>
            </div>

            <div className="col-4 pe-0 ps-2">
              <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/muhtemeller`}
                    className={`btn btn-muhtemeller ${params.subpage === 'muhtemeller' ? 'active' : ''}`}>MUHTEMELLER</Link>
            </div>
            <div className="col-4 pe-0 ps-2">
              <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/agf`}
                    className={`btn btn-agf ${params.subpage === 'agf' ? 'active' : ''}`}>AGF</Link>
            </div>
          </div>
        </>
        : <div className="row">

          <div className="col-12 mb-2 mb-sm-0 col-lg-3 p-0 px-sm-2">
            <label className="hidden-sm">HİPODROMLAR</label>
            <select onChange={(e: any) => {
              clearTjkBetSlipOptions();
              navigate(`${paths.betting.url}/${e.target.value.toLowerCase()}`);
            }} value={tjkBetSlipOptions.hippodrome.keyword+tjkBetSlipOptions.hippodrome._id}>
              {availableHippodromes.map((hippodrome: any, keyHippodrome: number) => {
                if (hippodrome.wager_details.length > 0 ) {
                  return (<option key={`keyHippodrome-${keyHippodrome}`}
                                  value={hippodrome.keyword+hippodrome._id}>{hippodrome.hippodrome} {new Date(hippodrome.date).getDate() <= new Date().getDate() ? <>{hippodrome.nextRun && ` - ${hippodrome.nextRun.no}. Koşu ${hippodrome.nextRun.time}`}</>: <> - ({dateFormat(hippodrome.date)})</> }</option>)
                }
              })}
            </select>
          </div>
           <div className="col-6 col-lg-2 p-0 pe-1 pe-sm-2">

             {tjkBetSlipOptions.bettingType && <>
               <label className="hidden-sm">BAHİS TÜRÜ</label>
               <select onChange={(e: any) => {
                 selectBettingType(e.target.value);
                 tjkBetSlipOptions.complete = false;
                 betSlipActionSubject.next(true);
               }} value={tjkBetSlipOptions.bettingType.id}>
                 {availableBettingTypes.map((bettingType: any, keyBettingType: number) => {
                   return (
                       <option key={`keyBettingType-${keyBettingType}`}
                               value={bettingType.id}>{bettingType.name}</option>)
                 })}
               </select>
             </>}
           </div>
          <div className="col-6 col-lg-2 p-0 ps-1 pe-sm-2">

            {tjkBetSlipOptions.bettingSubType &&
                <>
                  <label className="hidden-sm">KOŞU</label>
                  <select onChange={(e: any) => {
                    selectBettingSubType(e.target.value);
                  }} value={tjkBetSlipOptions.bettingSubType.id}>
                    {availableBettingSubTypes.map((bettingSubType: any, keyBettingSubType: number) => {
                      return (<option key={`keyBettingType-${keyBettingSubType}`}
                                      value={bettingSubType.id}>{bettingSubType.label}</option>)
                    })}
                  </select>
                </>}
          </div>

          <div className="col-lg-5 p-0 px-sm-2 pt-2 pt-sm-4">
        <div className="row">
          <div className="col-4 col-lg-4 pe-1 ps-lg-1">
            <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}`} onClick={() => {
              // setMobileBetslip(false)
              mobileBetSlipActions.isOpen = false;
              mobileBetSlipSubject.next('update')
            }} className={`btn btn-muhtemeller ${!params.subpage ? 'active' : ''}`}>
              BAHİS YAP
            </Link>
          </div>
          {/*{!isMobile && <div className="col-4 col-lg-3 pe-1 ps-lg-1">*/}
          {/*  <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}/yorumlar`}*/}
          {/*        className={`btn btn-muhtemeller ${params.subpage === 'yorumlar' ? 'active' : ''}`}>YORUMLAR</Link>*/}
          {/*</div>}*/}
          <div className="col-4 col-lg-4 pe-1 ps-1">
            <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/muhtemeller`}
                  className={`btn btn-muhtemeller ${params.subpage === 'muhtemeller' ? 'active' : ''}`}>MUHTEMELLER</Link>
          </div>
          <div className="col-4 col-lg-4 ps-1">
            <Link to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/agf`}
                  className={`btn btn-agf ${params.subpage === 'agf' ? 'active' : ''}`}>AGF</Link>
          </div>
        </div>
      </div>
    </div>}
  </div>)
}
